.PageBqMob{
  margin-top: 10px;
  width: 100%;
  position: relative;
  :global{
    .box{
      padding: 0 40px;
      width: 100%;
      box-sizing: border-box;
      .pageItem{
        width: 100%;
        overflow: hidden;
        .animationPage{
          width: 100%;
          white-space: nowrap;
          transition: all .2s ease-out;
          .pageOne{
            display: inline-block;
            width: 60px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
    .left{
      position: absolute;
      top: 1px;
      left: 15px;
    }
    .right{
      position: absolute;
      right: 15px;
      top: 1px;
    }
  }
}
