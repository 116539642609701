.reward {
  padding-top: 70px;

  :global {
    .header {
      height: 216px;
      display: flex;
      align-items: center;
      padding: 0 100px;
      border-bottom: 2px solid #fff;

      .zh {
        font-size: 60px;
        margin-right: 33px;
      }

      .cn {
        font-size: 30px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }

      .cn_eg {
        font-size: 50px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
      }
    }

    .list {
      padding: 22px 100px 0;

      .li {
        border-bottom: 1px solid #fff;
        padding: 70px 0;
        display: flex;
        flex-direction: column;

        &:last-child {
          border: none;
        }

        .label {
          font-size: 42px;
          color: #F38400;
          line-height: 62px;
          margin-bottom: 26px;
        }

        .list_p {
          font-size: 20px;
          line-height: 38x;
        }

        .html {
          width: 100%;
        }
      }
    }
  }
}

.awardTable {
  border-collapse: collapse;
  margin-top: 74px;
  font-size: 20px;
  border-color: #ffffff;
  width: 100%;
  border: 1px solid #ffffff;
  text-align: center;

  thead {
    th {
      height: 50px;
      border: 1px solid #ffffff;
    }
  }

  tbody {
    td {
      height: 50px;
      color: #F38400;
      font-family: 'HanSerifSC';
      border-color: #ffffff;
      font-size: 14px;
      border: 1px solid #ffffff;

      &:first-child {
        color: #ffffff;
      }
    }
  }
}