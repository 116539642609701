.newsList{
  margin-bottom: 63px;
  :global{
    .border{
      width: 100%;
      height: 1px;
      background-color: #fff;
      margin-bottom: 15px;
    }
  }
  :global{
    .listBox{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 15px 0;
      .blockLeft{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .blockRight{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
      }
      .imgBox{
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
      .img{
        width: 100%;
        object-fit: contain;
        transition: all .3s ease-out;
        transform: scale(1);
        &:hover{
          transform: scale(1.1);
        }
      }
      .cardFoot{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: hidden;
        line-height: 18px;
        margin-top: 18px;
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        .data{
          font-size: 11px;
          margin-top: 7px;
        }
      }
      .cardFootRight{
        margin-top: 18px;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 16px;
        .word{
          width: auto;
        }
        .data{
          margin-top: 7px;
          font-size: 11px;
        }
      }
      .cardFootCenter{
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 16px;
        width: 111px;
        margin-right: 40px;
        .word{
          width: auto;
        }
        .data{
          margin-top: 7px;
          font-size: 11px;
        }
      }
      .cardFootLeft{
        display: block;
        display: flex;
        margin-left: 24px;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 16px;
        width: 111px;
        .word{
          width: auto;
        }
        .data{
          margin-top: 7px;
          font-size: 11px;
        }
      }
    }
  }
}