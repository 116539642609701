.moduleBlock {
  margin-top: 104px;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  height: 611px;
  position: relative;
  width: 100%;

  :global {
    .paris {
      position: absolute;
      top: 60px;
      left: -120px;
    }

    .newYork {
      position: absolute;
      top: 184px;
      left: -460px;
    }

    .names {
      position: absolute;
      width: 275px;
      font-size: 17px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      bottom: 59px;
      left: 54px;
    }

    .imgs {
      width: 280px;
      height: 400px;
      position: absolute;
      right: 45px;
      top: -5px;
      transform: rotate(25deg);
      z-index: 20;
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #FFFFFF;
      position: absolute;
      bottom: 211px;
      z-index: 10;
    }

    .eng {
      z-index: 30;
      position: absolute;
      top: 60px;
      right: 40px;
      width: 255px;
      height: 93px;
      font-size: 123px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      font-style: italic;
      color: #F38400;
      line-height: 63px;
    }
  }
}