.ColumnPC {
  border-bottom: 2px solid #fff;

  .group {
    min-height: 64px;
    padding: 0 100px;

    .columns {
      display: flex;
      justify-content: space-between;

      // a {
      //   z-index: 2;
      // }

      .columnItem {
        min-width: 50px;
        height: auto;
        opacity: .5;
        cursor: pointer;
        margin-right: 15px;

        a {
          width: 100%;
          display: inline-block;
          font-size: 20px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          min-width: 50px;
        }
      }

      .selected {
        opacity: 1;
      }
    }
  }
}