.mobMhy {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  :global {
    .title {
      margin-top: 35px;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      // align-items: center;

      .zh {
        font-size: 30px;
        // line-height: 20px;
        position: relative;
        font-weight: 500;
        top: 50px;
        opacity: 0;
        left: 0;
        transition: top .3s ease-in-out;
        margin-right: 19px;
      }

      .cn {
        font-size: 15px;
        color: #F38400;
        font-family: 'HanSerifSC';
        position: relative;
        top: 50px;
        opacity: 0;
        left: 0;
        transition: top .3s ease-in-out .1s;
      }

      .en {
        font-size: 25px;
      }

      .active {
        top: 0;
        opacity: 1;
      }
    }

    .wrap {
      position: relative;
      width: 100%;
      height: 450px;
      margin-top: 150px;

      .left,
      .right {
        width: 290px;
        height: 290px;
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        left: calc(50% - 145px);
        transition: all .6s ease-in-out;
        opacity: 0;
      }

      .left {
        border: 3px solid #F38400;
        z-index: 2;

        .left_title {
          text-align: center;
          color: #F38400;
          font-size: 18px;
          font-weight: bold;
          font-family: 'PingFang';
          line-height: 19px;
          margin-bottom: 13px;
        }

        .left_content {
          margin-top: 18px;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
        }
      }

      .right {
        background: #1B1B1B;

        .right_title {
          text-align: center;
          color: #F38400;
          font-size: 18px;
          font-weight: bold;
          font-family: 'PingFang';
          line-height: 19px;
        }

        .right_content {
          width: 84%;
          word-break: break-word;
          margin-top: 18px;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
        }
      }

      .active_left {
        transform: translateY(-110px);
        opacity: 1;
      }

      .active_right {
        transform: translateY(110px);
        opacity: 1;
      }
    }
  }
}