:root {
  --background-image: url('../../../../assets/img/brand.png');
  --background-hover: url('../../../../assets/img/brand_yellow.png');
}

$Background: var(--background-image);
$BackgroundHover: var(--background-hover);

.BrandsPc {
  padding: 100px 0;

  .h2 {
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .brandTitle {
      margin-right: 33px;
    }

    .brand {
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }

    .hint {
      font-size: 16px;
      color: #fff;
      opacity: 1 !important;
      margin-left: 32px;
      transform: translateY(25px);
    }
  }

  ul.picture {
    margin-top: 0;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    li {
      height: 100px;
      width: 200px;

      .photos {
        height: 100%;
        width: 100%;
        position: relative;

        &:hover {
          transform: scale(0.85) !important;
        }

        .img {
          z-index: 50;
          // background: url(../../../../assets/img/brand.png) 0 0 no-repeat;
          background: $Background 0 0 no-repeat;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          backface-visibility: hidden;
          transform: scale(.8) translate(-50%, -50%);

          &:hover {
            z-index: 10;
            // background: url(../../../../assets/img/brand_yellow.png) 0 0 no-repeat;
            background: $BackgroundHover 0 0 no-repeat;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-40%, -40%);
            backface-visibility: hidden;
            opacity: 1;
          }
        }
      }

      .unactive {
        transform: scale(.75);
        opacity: 1;

        &:hover {
          opacity: 1.2;
          transform: scale(0.9);
        }
      }

      .active {
        opacity: 0.6;
        animation-name: breath;
        animation-duration: 2500ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        &:hover {
          animation-play-state: paused;
          opacity: 1;
          transform: scale(0.85) !important;
        }
      }

      .showNo {
        animation-play-state: paused;
        opacity: 0.5;
      }

      .showLight {
        opacity: 1;
        transform: scale(.80);
      }

      @keyframes breath {
        from {
          opacity: 0.7;
          transform: scale(.75);
        }

        50% {
          opacity: 1;
          transform: scale(.85);
        }

        to {
          opacity: 0.7;
          transform: scale(.75);
        }
      }
    }
  }

  ul.titles {
    width: 100%;
    margin-top: 54px;
    padding-left: 95px;
    padding-right: 95px;

    .bottom {
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .brand {
        width: 150px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 85px;
        font-size: 20px;
        font-family: 'AlibabaPuHuiTiR';
        font-weight: 400;

        .name {
          color: #999999;
          cursor: pointer;
          height: 20px;
          width: 90px;
          line-height: 20px;
          text-align: center;
        }

        .changeWhite {
          color: #fff;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}