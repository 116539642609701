.CenterPicturePC {
  padding: 0 100px;
  margin-bottom: 100px;

  .pictureInform {
    .topPhoto {
      width: 100%;
      height: 912px;
      margin-top: 141px;
      display: flex;

      .outside {
        width: 478px;
        height: 506px;
        margin-right: 90px;
        object-fit: cover;
        overflow: hidden;

        .leftPhoto {
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          object-fit: cover;
          transition: all .3s ease-out;
          transform: scale(1);

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .outsideRight {
        width: 1153px;
        height: 912px;
        overflow: hidden;
        object-fit: cover;

        .rightPhoto {
          transition: all .3s ease-out;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          transform: scale(1);

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .bigPhoto {
      margin-top: 126px;
      width: 1584px;
      // height: 970px;
      height: auto;
      object-fit: cover;
      // overflow: hidden;

      .photoItem {
        transition: all .3s ease-out;
        transform: scale(1);
        width: 0;
        height: 0;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;

        &:hover {
          transform: scale(1.05);
          transition-property: all .3s;
        }
      }
    }
  }
}