.ServiceAdvantagesPC {
  padding: 100px;
  padding-bottom: 0;

  .contents {
    padding: 100px;
    padding-bottom: 170px;
    position: relative;
    height: 533px;
    box-sizing: content-box;

    .advantage {
      position: absolute;
      left: calc(50% - 291.5px);
      transition: all 1s;
      display: flex;
      flex-direction: column;
      align-items: center;

      .big {
        width: 483px;
        height: 483px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .small {
          width: 402px;
          height: 402px;
          border: 12px solid #F38400;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .word {
            font-size: 26px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }

      .describe {
        text-align: center;
        margin-top: 66px;
        font-size: 20px;
      }
    }

    .big0 {
      opacity: 0;
    }

    .big2 {
      opacity: 0;
    }
  }

  .even {
    .advantage {
      opacity: 1;

      &:nth-child(1) {
        transform: translateX(-550px);

        .big {
          opacity: 1;
        }
      }

      &:nth-child(2) {

        // transform: translateX(-550px);
        .big {
          opacity: 1;
        }
      }

      &:nth-child(3) {
        transform: translateX(550px);

        .big {
          opacity: 1;
        }
      }
    }
  }

  .odd {
    .advantage {
      &:nth-child(1) {
        transform: translateX(-350px);

        .big {
          opacity: 1;
        }
      }

      &:nth-child(2) {
        transform: translateX(350px);

        .big {
          opacity: 1;
        }
      }
    }
  }

  .one {
    .advantage {
      .big {
        opacity: 1;
      }
    }
  }
}