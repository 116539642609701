.PagesPC {
  :global {
    .bottomPage {
      width: 100%;
      height: 55px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 20px 15px 20px 15px;
      margin-bottom: 55px;

      .ant-pagination {
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        font-size: 12px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        line-height: 15px;

        .ant-pagination-item a {
          color: #999999;
        }

        .ant-pagination-item-link {
          border: none;
        }

        .ant-pagination-item {
          border: none;
          width: 27.7px;
          height: 16px;
          padding-bottom: 5px;
          line-height: 9px;

          a {
            // width: 27.7px;
            height: 9px;
          }
        }

        .ant-pagination-item-active {
          border-bottom: 2px solid #F38400;

          a {
            color: #FFFFFF;
          }
        }

        .ant-pagination-item-ellipsis {
          color: #fff;
          font-size: 20px;
          font-family: 'HanSerifSC';
          font-weight: 400;
        }

        .ant-pagination-prev {
          position: absolute;
          left: 0;
        }

        .ant-pagination-next {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}