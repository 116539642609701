.bq_card_slip{
  width: 534px;
  overflow: hidden;
  height: auto;
  position: relative;
  &:hover{
    :global{
      .slip_back{
        z-index: 1;
        transform: rotateY(0);
      }
    }
  }
  :global{
    .slip_front{
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 100px;
      border: 1px solid #fff;
      box-sizing: border-box;
      // border-left: none;
    }
    .slip_back{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: rotateY(90deg);
      transition: all .3s ease-out;
    }
  }
}
