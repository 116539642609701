.home{
  position: relative;
  :global{
    .top{
      position: fixed;
      bottom: 105px;
      right: 70px;
      width: 113px;
      height: 113px;
      z-index: 9999;
      cursor: pointer;
    }
  }
}