.DevelopHistoryPC {
  margin-bottom: 76px;

  .developBox {
    margin-top: 35px;
    margin-left: 90px;
    display: flex;
    position: relative;
    min-height: 840px;

    &.heightWrap {
      min-height: 1200px;
    }

    .left {
      width: 93px;
      border-left: 1px dotted #525252;

      .time {
        margin-top: 95px;

        .timeItem {
          display: flex;
          margin-bottom: 15px;
          height: 50px;

          .rod {
            width: 10px;
            height: 1px;
            background: #525252;
            margin: auto;
            margin-right: 23px;
            margin-left: 0;

          }

          .timeDetaile {
            width: 50px;
            height: 50px;
            font-size: 20px;
            font-family: 'HanSansHWK';
            font-weight: bold;
            color: #FFFFFF;
            line-height: 55px;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      position: absolute;
      right: 350px;
      top: 144px;
      height: 100%;
      width: 1023px;

      .photo {
        width: 563px;
        height: 699px;
        object-fit: cover;
      }

      .bigTime {
        width: 712px;
        position: absolute;
        height: 168px;
        left: 437px;
        top: 238px;
        font-size: 140px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        border-bottom: 2px solid #FFFFFF;
      }

      .word {
        position: absolute;
        left: 438px;
        top: 478px;
        bottom: 84px;
        width: 712px;
        max-height: 500px;
        overflow-y: scroll;
        font-size: 20px;
        font-family: 'HanSansHWK';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38px;
      }
    }
  }
}