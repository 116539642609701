.joinPc {

  :global {
    .header {
      height: 216px;
      display: flex;
      align-items: center;
      padding: 0 100px;

      .zh {
        font-size: 60px;
        margin-right: 33px;
      }

      .cn {
        font-size: 30px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }

      .cn_eg {
        font-size: 50px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
      }
    }

    .list {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 38px;

      li {
        width: 25%;
        text-align: center;

        a {
          cursor: pointer;
          display: block;

          &:hover {
            color: #F38400;
          }
        }

        img {
          width: 200px;
          height: 200px;
          margin-top: 20px;
        }

        .joinImg {
          width: 140px;
          height: 140px;
          margin: 40px;
        }

        .fw600 {
          font-weight: 600;
        }
      }

      .mode {
        font-size: 42px;
        color: #F38400;
        line-height: 62px;
        margin-bottom: 26px;
        font-family: "HanSansHWK";
      }
    }
  }
}