.BusinessCaseMob {
  margin-bottom: 60px;
  margin-top: 60px;

  .title {
    padding: 0 14px;
    padding-top: 0;
  }

  .borders {
    height: 1px;
    background: #FFFFFF;
    width: 100%;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 21px 15px 22px 15px !important;
  }

  .panel_header {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    padding: 21px 15px 22px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .boxItem {
      display: flex;
      flex-direction: column;
      width: 230px;

      .panel_zh {
        font-size: 18px;
        user-select: none;
        font-family: 'PingFang';
        font-weight: 500;
        transition: color .3s;
        will-change: color;
        margin-bottom: 11px;
      }

      .panel_en {
        width: 230px;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        font-family: 'Alibaba PuHuiTi';
        font-weight: 400;
        color: #999999;
        user-select: none;
        font-family: 'HanSerifSC';
        transition: color .3s;
        will-change: color;
        line-height: 15px;
      }
    }

    .icon {
      width: 22px;
      height: 22px;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 1px solid #fff;
    padding: 25px 0;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: column;

    .cont_img {
      width: 346px;
      height: 322px;
      object-fit: cover;
      vertical-align: middle;
      font-family: 'object-fit: cover';
      margin-bottom: 37px;
    }

    .cont {
      width: 100%;
      word-break: break-all;
      vertical-align: middle;
      white-space: normal;
      font-size: 11px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #959595;
      line-height: 21px;
    }
  }
}