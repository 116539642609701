.WordContentMob{
  height: auto;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 13px ;
  overflow: hidden;
  .leftTitle{
    width: 124px;
    vertical-align: middle;
    font-size: 16px;
    font-family: 'PingFang';
    font-weight: bold;
    color: #FFFFFF;
    user-select: none;
    transition: all .8s ease-out;
    transform: translateY(80px);
    opacity: 0;
  }
  .words{
    font-size: 12px;
    font-family: 'PingFang';
    font-weight: 400;
    color: #FFFFFF;
    line-height: 19px;
    width: 266px;
    height: auto;
    transition: all .8s ease-out;
    transform: translateY(50px);
    opacity: 0;
  }
  .transTop {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
}