.TimeIntroduceMob{
  margin-bottom: 55px;
  padding-top: 80px;
  .ant-collapse{
    border: 1px solid #FFFFFF;
    border-right: none;
    border-left: none;
  }
  .ant-collapse-borderless > .ant-collapse-item{
    border: 1px solid #FFFFFF ;
    border-right: none;
    border-left: none;
  }
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header{
    cursor: pointer;
  }
  .panel_header{
    display: flex;
    // height: 114px;
    // line-height: 114px;
    align-items: center;
    overflow: hidden;
    .time{
      width: 124px;
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-family: 'PingFang';
      font-weight: bold;
      color: #FFFFFF;
      transition: all .8s ease-out;
      transform: translateY(100px);
      opacity: 0;
      user-select: none;
    }
    .word{
      width: 239px;
      display: inline-block;
      user-select: none;
      font-size: 12px;
      font-family: 'PingFang';
      font-weight: 400;
      line-height: 19px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      transition: all .8s ease-out;
      transform: translateY(100px);
      opacity: 0;
    }
    .word{
      display: inline-block;
    }
  }
  .transTop {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
  .panel_cont{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .cont_img{

      width: 346px;
      height: 188px;
      object-fit: cover;
    }
  }
}