.TimeIntroducePC{
  margin-bottom: 136px;
  padding-top: 180px;
  .ant-collapse{
    border: 1px solid #FFFFFF;
    border-right: none;
    border-left: none;
  }
  .ant-collapse-borderless > .ant-collapse-item{
    border: 1px solid #FFFFFF ;
    border-right: none;
    border-left: none;
  }
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header{
    cursor: default;
  }
  .panel_header{
    display: flex;
    // height: 154px;
    padding-left: 99px;
    align-items: center;
    justify-content: center;
    line-height: 154px;
    overflow: hidden;
    .time{
      width: 435px;
      display: inline-block;
      vertical-align: middle;
      font-size: 34px;
      font-family: 'PingFang';
      font-weight: bold;
      color: #FFFFFF;
      user-select: none;
      transition: all 1s ease-out;
      transform: translateY(150px);
      opacity: 0;
    }
    .word{
      width: 1000px;
      display: inline-block;
      user-select: none;
      font-size: 20px;
      font-family: 'PingFang';
      font-weight: 400;
      line-height: 38px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      transition: all 1s ease-out;
      transform: translateY(150px);
      opacity: 0;
    }
    .word{
      display: inline-block;
    }
  }
  .transTop {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
  .panel_cont{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 80px 84px 0;
    .cont_img{
      width: 1268px;
      height: 689px;
      object-fit: cover;
    }
  }
}