.bqVideo {
  width: 100%;
  height: calc(100vh - 122px);
  object-fit: cover;
  display: block;
}

.bqImage {
  width: 100%;
  height: calc(100vh - 122px);
  // object-fit: cover;
  display: block;
  // font-family: 'object-fit: cover';
}