.form {
  position: relative;
  width: 100%;
  height: 217px;
  padding: 70px 100px 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  :global {
    .left {
      display: flex;
      align-items: center;

      .zh {
        font-size: 60px;
        line-height: 80px;
        margin-right: 35px;
      }

      .cn {
        color: #F38400;
        font-family: 'HanSerifSC';
        font-size: 30px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 1000px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search_text {
          font-size: 18px;
          line-height: 24px;
          font-family: 'HanSerifSC';
        }

        .search_icon {
          font-size: 24px;
          width: 24px;
          height: 24px;
          color: #F38400;
          cursor: pointer;
        }
      }

      .input {
        border: none;
        border-bottom: 1px solid rgba($color: #fff, $alpha: .3);
        width: 100%;
        background-color: transparent;
        outline: none;
        caret-color: #fff;
        padding: 0 5px;
        box-sizing: border-box;
        height: 36px;
        line-height: 36px;
        font-size: 18px;
        color: #fff;
      }
    }

    .types {
      position: absolute;
      top: -66px;
      right: 100px;
      height: 66px;
      // width: 350px;
      display: flex;

      // justify-content: space-between;
      .type {
        height: 42px;
        padding: 0 31px;
        text-align: center;
        line-height: 42px;
        color: #999999;
        cursor: pointer;
        font-size: 16px;
        margin-right: 67px;

        &:last-child {
          margin-right: 0;
        }
      }

      .type_active {
        background-color: #F38400;
        color: #fff;
        border-radius: 21px;
      }
    }
  }
}