.searchListMob {
  :global {
    .title {
      display: flex;
      padding: 50px 0 15px 15px;
      border-bottom: 1px solid #fff;

      .ch {
        font-size: 36px;
        font-family: 'HanSansHWK';
        font-weight: 500;
        margin-right: 16px;
        color: #FFFFFF;
      }

      .eg {
        font-size: 30px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 65px;
      }
    }

    .bottom {
      padding: 15px;

      .block {
        margin-bottom: 45px;

        .oneTitle {
          padding-bottom: 15px;
          border-bottom: 1px solid #fff;
          display: flex;
          margin-bottom: 25px;

          .ch {
            font-size: 23px;
            font-family: 'HanSansHWK';
            font-weight: 500;
            color: #FFFFFF;
            margin-right: 12px;
          }

          .eg {
            font-size: 15px;
            font-family: 'HanSerifSC';
            font-weight: 400;
            color: #F38400;
            line-height: 40px;
          }
        }

        .list {
          display: flex;
          flex-direction: column;

          .listBlock {
            cursor: pointer;
            display: flex;
            margin-bottom: 10px;
            align-items: center;

            .right {
              width: 9px;
              height: 6px;
              color: #fff;
              margin-right: 16px;
            }

            .word {
              font-size: 13px;
              font-family: 'HanSansHWK';
              font-weight: 500;
            }

            &:hover {
              color: #F38400;
            }
          }
        }
      }
    }

    .noResult {
      width: 100%;
      height: 100px;
      line-height: 100px;
      margin: auto;
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-family: 'HanSerifSC';
      font-weight: 500;
    }

    .more {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 35px;
      background: #F38400;
      border-radius: 18px;
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 80px;
    }
  }
}