.ClubListPc {
  padding-top: 100px;

  .h2 {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #fff;
    will-change: width opacity;
    white-space: nowrap;
  }

  .title_zh {
    font-size: 42px;
    user-select: none;
    color: #F38400;
  }

  .ant-collapse-item {
    border-bottom: 2px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 100px 0 95px !important;
  }

  .panel_header {
    height: 118px;
    padding: 0 100px 0 95px;
    line-height: 118px;
    overflow: hidden;

    .panel_zh {
      display: inline-block;
      vertical-align: middle;
      width: 1060px;
      font-size: 36px;
      user-select: none;
      transition: opacity .3s;
      will-change: opacity;
    }

    .panel_en {
      display: inline-block;
      vertical-align: middle;
      width: 605px;
      font-size: 24px;
      color: #F38400;
      user-select: none;
      font-family: 'HanSerifSC';
      transition: opacity .3s;
      will-change: opacity;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 44px;
      height: 44px;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 2px solid rgba($color: #fff, $alpha: .4);
    padding: 30px 0 30px 0;
    overflow: hidden;
    white-space: nowrap;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    flex-wrap: wrap;

    .cont_img {
      margin-top: 67px;
      width: 860px;
      height: 600px;
      object-fit: cover;
      margin-right: 24px;
      vertical-align: middle;
      font-family: 'object-fit: cover';
    }

    .cont_imgTwo {
      width: 700px;
      height: 600px;
      margin-top: 67px;
      object-fit: cover;
      font-family: 'object-fit: cover';

      &:nth-child(2n) {
        width: 860px;
        height: 600px;
        margin-top: 67px;
        object-fit: cover;
        font-family: 'object-fit: cover';
        margin-right: 24px;
      }
    }

    .cont {
      word-break: break-all;
      vertical-align: middle;
      white-space: normal;
      font-size: 24px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #959595;
      line-height: 37px;
    }
  }

  @keyframes panel_cont_open {
    0% {
      transform: rotateX(90deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  @keyframes panel_cont_close {
    0% {
      transform: rotateX(0);
    }

    100% {
      transform: rotateX(0deg);
    }
  }
}