.VisionMissionPC {
  .target {
    height: 445px;
    border-bottom: 2px solid #fff;
    display: flex;

    .mission {
      width: 959px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 98px;
      border-right: 2px solid #F9F9F9;

      &:last-child {
        border-right: 0;
      }

      .name {
        display: flex;

        .ch {
          font-size: 56px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }

        .eng {
          height: 30px;
          text-indent: 33px;
          font-size: 40px;
          font-family: HanSerifSC;
          font-weight: 400;
          color: #F38400;
          line-height: 110px;
          margin-bottom: 32px;
        }
      }

      .describe {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }

      .page {
        height: 16px;
        font-size: 30px;
        font-family: Lucida Calligraphy;
        font-weight: normal;
        color: #F38400;
        line-height: 60px;
        position: absolute;
        bottom: 67px;
      }
    }
  }
}