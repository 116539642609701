@import './font.scss';
@import './css.scss';
@import './keyframes.scss';

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

@supports (bottom: env(safe-area-inset-bottom)) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
  }
}

@media screen and (min-width: 1079px) {
  html {
    // ui 宽度 1920px
    // 1vw = 19.2px
    // rootValue = 3.75 * 19.2
    // font-size = 3.75vw = 19.2 * 3.75 px
    // write = font-size / rootValue = 1px
    // 在ui宽度下为 72px
    font-size: 3.75vw !important;
  }
}

@media screen and (max-width: 1080px) {
  html {
    // ui 宽度 375px
    // 1vw = 3.75px
    // rootValue = 3.75 * 19.2
    // font-size = 19.2vw = 19.2 * 3.75 px
    // write = font-size / rootValue = 1px
    // 在ui宽度下为 72px
    font-size: 19.2vw !important;
  }
}


body {
  margin: 0;
  padding: 0;
  font-family: 'HanSansHWK', 'HanSerifSC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  overflow-x: hidden;
  color: #fff;
}

ul,
ol,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}

img {
  display: inline-block;
  line-height: 1;
}

// 滚动条样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
  transition: all .2s;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.cn,
.en {
  font-family: 'HanSerifSC', sans-serif;
}

.zh {
  font-family: "HanSansHWK", sans-serif;
}

/* 超出两行显示...需要写宽度 */
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}