.sense {
  width: 100%;
  height: 417px;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  overflow: hidden;
  background: url(../../../../assets/img/backCircle.png) 0 0 no-repeat;

  :global {
    .title {
      color: #F38400;
      font-size: 42px;
      line-height: 22px;
      position: absolute;
      top: 120px;
      opacity: 0;
      left: 102px;
      transition: all .3s;
    }

    .title_active {
      opacity: 1;
      top: 60px;
    }

    .list {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: flex;
      padding: 0 102px;
      justify-content: space-between;
      align-items: center;

      li {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        position: relative;
        top: 200px;
        left: 0;
        transition: all ease-in-out;
        opacity: 0;
        text-align: center;

        &.li_active {
          top: 0;
          opacity: 1;
        }

        .zh {
          font-size: 32px;
          line-height: 44px;
        }

        .cn {
          font-size: 18px;
          line-height: 30px;
          color: #F38400;
          font-family: 'HanSerifSC';
        }
      }
    }
  }
}