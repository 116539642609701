.aboutMob {
	.h2 {
		height: 88px;
		box-sizing: border-box;
		padding-top: 20px;
		line-height: 64px;
		font-size: 36px;
		padding-left: 15px;
		overflow: hidden;
		font-family: 'HanSansHWK';

		// display: flex;
		.aboutTitle {
			margin-right: 17px;
		}

		.aboutbq {
			font-size: 30px;
			line-height: 45px;
			transform: translateY(13px);
			color: #f38400;
			font-family: 'HanSerifSC';
		}
	}

	.border {
		height: 1px;
		background-color: #fff;
	}

	.cont {
		padding: 40px 0;
		height: 100%;
		box-sizing: content-box;

		// contLeft
		.contLeft {
			width: 90vw;
			position: relative;
			display: flex;
			justify-content: flex-end;
			padding-right: 6px;
		}

		.contLeftMedia {
			width: 240px;
			height: 300px;
			object-fit: cover;
			font-family: 'object-fit: cover';
			position: relative;
			z-index: 10;
		}

		.contLeftPosition {
			position: absolute;
			left: 20px;
			top: 0;
			font-size: 47px;
			line-height: 55px;
			z-index: 100;
		}

		// contRight
		.contRight {
			width: 90vw;
			position: relative;
			padding-left: 19px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.contRightTop {
			font-size: 26px;
			line-height: 40px;
			margin-top: 52px;
			margin-bottom: 36px;
			color: #F38400;
			font-style: italic;

		}

		.contRightTop_eg {
			margin-top: 56px;
			font-size: 26px;
			line-height: 40px;
			font-family: 'HanSerifSC';
			font-weight: 400;
			margin-bottom: 36px;
			color: #F38400;
			font-style: italic;
		}

		.contRightBtm {
			display: flex;
			padding-bottom: 6px;
		}

		.contRightBtmLeft {
			width: 120px;
			height: 35px;
			line-height: 35px;
			border-radius: 18px;
			text-align: center;
			background-color: #fff;
			color: #000;
			font-size: 13px;
			cursor: pointer;
		}

		.contRightBtmRight {
			width: 120px;
			height: 35px;
			line-height: 35px;
			border-radius: 18px;
			background-color: #000;
			border: 1px solid #fff;
			font-size: 13px;
			margin-left: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.play {
			width: 13px;
			height: 13px;
			margin-left: 7px;
		}
	}
}

.mobModal {

	// width: 300px;
	//  height: 300px;
	.ant-modal {
		width: 375px !important;
		margin: 0;
	}

	.ant-modal-close {
		color: #fff;
		position: absolute;
		top: -10px;
		right: -15px;
	}

	.ant-modal-body {
		padding: 0;
		padding-top: 30px;
	}

	.openVideo {
		width: 375px;
		// height: 300px;
		object-fit: contain;
		font-family: 'object-fit: contain';
	}
}