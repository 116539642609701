.bannerHeader {
	width: 100vw;

	.header {
		width: 100%;
		height: 50px;
		z-index: 99;
		background-color: #000;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;

		.searchWord {
			width: calc(100% - 60px);
			background: transparent;
			border: 0 none;
			border-bottom: 1px solid rgba($color: #fff, $alpha: .34);
			// color: #FFFFFF; 
			resize: none;
			outline: none;
			/*清除选中效果的默认蓝色边框 */
			-webkit-appearance: none;
			/*清除浏览器默认的样式 */
			line-height: normal; //光标问题 

			&::-webkit-input-placeholder {
				/* WebKit browsers */
				color: #fff;
				font-family: 'HanSerifSC';
				font-weight: 400;
			}

			&:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #fff;
				font-family: 'HanSerifSC';
				font-weight: 400;
			}

			&::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #fff;
				font-family: 'HanSerifSC';
				font-weight: 400;
			}

			&::-ms-input-placeholder {
				/* Internet Explorer 10+ */
				color: #fff;
				font-family: 'HanSerifSC';
				font-weight: 400;
			}

			&:focus {
				color: #8b8791;
			}
		}

		.leftMenu {
			width: 20px;
			height: 10px;

			.long {
				width: 18px;
				background-color: #F38400;
				height: 1px;
				margin-bottom: 4px;
			}

			.short {
				width: 9px;
				background-color: #F38400;
				height: 1px;
			}
		}

		.logo {
			width: 105px;
			height: 12px;
			display: block;
			object-fit: cover;
		}

		.rightMenu {
			display: flex;
			align-items: center;

			.langeBtn {
				// width: 16px;
				height: 9px;
				line-height: 9px;
			}
		}

		.searchBtn {
			margin-right: 20px;
			height: 13px;
			width: 13px;
			font-size: 13px;
		}

		.close {
			width: 16px;
			height: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			.closeButton {
				width: 100%;
				height: 100%;
			}
		}
	}

	.selectBanner {
		background: rgba($color: #000000, $alpha: 0.7);
		z-index: 80000;
		position: fixed;
		width: 100%;
		height: 100%;

		.ant-collapse-content-box {
			padding: 0;
		}

		.ant-collapse>.ant-collapse-item>.ant-collapse-header {
			padding: 0;
		}

		.drawer {
			animation-duration: .3s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
			animation-iteration-count: 1;

			.border {
				height: 1px;
				background-color: #ffff;
				opacity: 0.5;
			}

			padding-top: 24px;
			background-color: #000;
			cursor: pointer;

			.colla {
				margin-bottom: 10px;

				.panel_item {
					border-bottom: 1px solid rgba($color: #ffff, $alpha: 0.5);
					padding: none;
				}
			}

			.panel_header {
				height: 48px;
				line-height: 48px;
				overflow: hidden;
				color: #FFFFFF;
				position: relative;

				.lightUrl {
					color: #F38400 !important;
				}

				.num {
					font-size: 16px;
					font-family: 'HanSerifSC';
					font-weight: 400;
					line-height: 48px;
					margin-left: 15px;
					margin-right: 50px;
					display: inline-block;
				}

				.name {
					width: 200px;
					font-size: 19px;
					font-family: 'HanSansHWK';
					font-weight: 500;
					display: inline-block;
					line-height: 48px;
				}

				.icon {
					display: inline-block;
					vertical-align: middle;
					width: 22px;
					height: 22px;
					position: absolute;
					right: 20px;
					top: 15px;
				}

				.avtive {
					color: #F38400;
				}
			}

			.menu {
				.sonItem {
					cursor: pointer;
					margin: 0 0 51px 79px;
					height: 30px;
					font-size: 13px;
					font-family: 'HanSansHWK';
					font-weight: 400;
					color: #FFFFFF;
					line-height: 24px;
					margin-bottom: 5px;
				}

				.light {
					color: #F38400;
				}

				.three {
					.threeItem {
						cursor: pointer;
						margin: 0 0 51px 79px;
						height: 30px;
						font-size: 13px;
						font-family: 'HanSansHWK';
						font-weight: 400;
						color: #999999;
						line-height: 24px;
						margin-bottom: 5px;
					}

				}
			}
		}
	}
}