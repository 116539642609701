.ActivityMob {
  .h2 {
    height: 55px;
    box-sizing: border-box;
    margin-top: 35px;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
    border-bottom: 1px solid #fff;
    will-change: width opacity;
    white-space: nowrap;
  }

  .title_zh {
    font-size: 21px;
    // font-family: 'PingFang';
    font-weight: bold;
    color: #F38400;
    line-height: 19px;
    user-select: none;
    color: #F38400;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .panel_header {
    height: 67px;
    line-height: 67px;
    overflow: hidden;

    .panel_zh {
      padding-left: 14px;
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #999999;
      user-select: none;
      transition: opacity .3s;
      will-change: opacity;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 1px solid #fff;
    padding: 30px 15px;
    overflow: hidden;
    white-space: nowrap;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .imgs {
      display: flex;
      width: 100%;

      .cont_imgTwo {
        &:first-child {
          width: 110px;
          height: 86px;
          object-fit: cover;
          margin-right: 4px;
          vertical-align: middle;
          font-family: 'object-fit: cover';
        }

        &:nth-child(2) {
          width: 129px;
          height: 133px;
          object-fit: cover;
          margin-right: 4px;
          vertical-align: middle;
          font-family: 'object-fit: cover';
        }

        &:last-child {
          width: 98px;
          height: 97px;
          object-fit: cover;
          margin-right: 4px;
          vertical-align: middle;
          font-family: 'object-fit: cover';
        }
      }
    }

    .data {
      width: 55px;
      height: 10px;
      font-size: 13px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 13px;
      margin-bottom: 24px;
    }

    .cont {
      font-size: 13px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #959595;
      line-height: 18px;
      width: 337px;
      word-break: break-all;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      margin-bottom: 35px;
    }
  }

  @keyframes panel_cont_open {
    0% {
      transform: rotateX(90deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  @keyframes panel_cont_close {
    0% {
      transform: rotateX(0);
    }

    100% {
      transform: rotateX(0deg);
    }
  }
}