.TitlePC {
  border-bottom: 2px solid #fff;
  margin-top: 100px;

  .title {
    padding: 0 100px;
    display: flex;
    height: 104px;
    box-sizing: border-box;
    margin-bottom: 36px;

    .chinese {
      font-size: 73px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #FFFFFF;
      margin-right: 31px;
    }

    .english {
      font-size: 60px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 140px;
    }
  }
}