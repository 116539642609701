.CaseLightMob {
  margin-top: 50px;

  .h2 {
    padding: 0 15px;
    font-size: 28px;
    overflow: hidden;
    font-family: PingFang;
    font-weight: 400;
    // margin-bottom: 10px;
  }

  .ch {
    padding: 0 15px;
    font-size: 30px;
    font-family: HanSerifSC;
    font-weight: 400;
    margin-bottom: 25px;
    color: #F38400;
  }

  .border {
    height: 2px;
    background-color: #fff;
  }
}