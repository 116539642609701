.NewsMob {
	padding: 20px 0 69px 0;

	.h2 {
		height: 50px;
		font-size: 36px;
		padding-left: 14px;
		overflow: hidden;
		display: flex;
		margin-right: 17px;
		font-family: 'HanSansHWK';

		.newTitle {
			margin-right: 17px;
		}

		.newbq {
			font-size: 30px;
			line-height: 60px;
			// transform: translateY(13px);
			color: #f38400;
			font-family: 'HanSerifSC';
		}
	}

	.border {
		height: 1px;
		background-color: #fff;
	}

	.content {
		padding: 41px 16px 0 15px;

		.opa {
			opacity: 1;
		}

		.opaHalf {
			opacity: 0.5;
		}

		.bottom {
			margin-bottom: 25px;

			.words {
				margin-top: 11px;
				font-size: 12px;
				font-family: 'PingFang';
				font-weight: bold;
				color: #ffffff;
				opacity: 0.5;
				height: auto;

				p {
					max-width: 236px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.time {
				margin-top: 4px;
				width: 67px;
				height: 15px;
				font-size: 11px;
				font-family: 'HanSansHWK';
				font-weight: bold;
				color: #ffffff;
				opacity: 0.5;
				margin-bottom: 25px;
			}
		}

		.blockOne {
			.photoOne {
				width: 345px;
				height: 180px;
				object-fit: contain;
				font-family: 'object-fit: contain';
			}
		}

		.blockTwo {
			.pictureTwo {
				width: 345px;
				height: 180px;
				object-fit: contain;
				font-family: 'object-fit: contain';
			}
		}
	}

	.look {
		width: 162px;
		display: flex;
		height: 25px;
		line-height: 15px;
		margin: 47px auto;
		border-bottom: 1px solid #fff;
		justify-content: space-between;
		padding-left: 5px;
		padding-right: 3px;
		cursor: pointer;

		.btn {
			height: 13px;
			margin-bottom: 8px;
		}
	}
}