.BottomPictureMob{
  .pictureInform{
    .bottomPhoto{
      width: 100%;
      height: 227px;
      display: flex;
      padding: 0 0 0 28px;
      .outsideLeft{
        width: 202px;
        height: 227px;
        margin-right: 10px;
        overflow: hidden;
        // object-fit: cover;
        .rightPhoto{
          width: 100%;
          height: 100%;
          &:hover{
            transform: scale(1.05);
            transition-property: all;
          }
        }
      }
      .outside{
        width: 106px;
        height: 140px;
        object-fit: cover;
        overflow: hidden;
        .leftPhoto{
          transition-property: all;
          &:hover{
            transform: scale(1.05);
            transition-property: all;
          }
        }
      }
      
    }
    .bigBottom{
      margin: 50px 0 0 13px;
      width: 313px;
      height: 201px;
      object-fit: cover;
      overflow: hidden;
      .photoItem{
        &:hover{
          transform: scale(1.05);
            transition-property: all;
        }
      }
    }
  }
}