.PresentMob {
  margin-top: 10px;
  position: relative;

  .imgBack {
    width: 100%;
    height: 247px;
    object-fit: cover;
  }

  .block {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #000;
    position: absolute;

    .num {
      height: 32px;
      font-size: 35px;
      font-family: 'LucidaCalligraphy';
      font-weight: normal;
      color: #F38400;
      line-height: 32px;
      margin-right: 9px;
      font-style: oblique;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      will-change: transform opacity;
    }

    .unit {
      font-size: 19px;
      font-weight: 400;
      color: #FFFFFF;
      margin-right: 6px;
      line-height: 25px;
    }

    .introduce {
      font-size: 14px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
    }

    .describe {
      font-size: 13px;
      font-family: 'HanSerifSC';
      line-height: 13px;
      font-weight: 400;
      color: #F38400;
    }

    @keyframes wordOpen {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.4);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  .block0 {
    top: 44px;
    left: 23px;

    .describe {
      width: 119px;
    }
  }

  .block1 {
    top: 86px;
    right: 7px;

    .describe {
      width: 73px;
    }
  }

  .block2 {
    top: 128px;
    left: 17px;

    .describe {
      width: 70px;
    }
  }

  .block3 {
    top: 172px;
    left: 85px;

    .describe {
      width: 150px;
    }
  }
}