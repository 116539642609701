.VisionMissionMob {
  .target {
    height: 177px;
    border-bottom: 1px solid #fff;
    display: flex;

    .mission {
      width: 187px;
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      justify-content: center;
      border-right: 1px solid #FFFFFF;

      &:last-child {
        border-right: 0;
      }

      .name {
        display: flex;

        .ch {
          font-size: 28px;
          font-family: "PingFang";
          font-weight: 500;
          color: #FFFFFF;
        }

        .eng {
          height: 15px;
          text-indent: 17px;
          font-size: 20px;
          font-family: "HanSerifSC";
          font-weight: 400;
          color: #F38400;
          line-height: 55px;
          margin-bottom: 15px;
        }
      }

      .describe {
        height: 13px;
        font-size: 13px;
        font-family: "PingFang";
        font-weight: 500;
        color: #FFFFFF;
      }

      .page {
        height: 8px;
        font-size: 15px;
        font-family: Lucida Calligraphy;
        font-weight: normal;
        color: #F38400;
        line-height: 30px;
        position: absolute;
        bottom: 35px;
      }
    }
  }
}