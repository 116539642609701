.TopBannerMob {

  .topInfor {
    padding: 51px 15px 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;

    img {
      width: 55px;
      height: 50px;
      object-fit: cover;
      // background-color: #FFFFFF;
      margin-bottom: 21px;
      object-fit: contain;
    }

    .slogan {
      font-size: 31px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 38px;
    }
  }

  .label {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    margin-top: 23px;
    padding-bottom: 27px;
    border-bottom: 1px solid #FFFFFF;

    .labelItem {
      // padding: 0 10px;
      height: 24px;
      border: 1px solid #434343;
      border-radius: 12px;
      font-size: 13px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      margin-right: 10px;
      text-align: center;
      line-height: 24px;
    }
  }

  .dataInfom {
    padding: 0 15px 0 15px;
    margin-top: 22px;
    display: flex;
    font-size: 12px;
    font-family: 'PingFang';
    font-weight: 400;
    color: #FFFFFF;

    .num {
      margin-right: 27px;
      display: flex;
      align-items: center;

      .icon {
        width: 12px;
        height: 7px;
        margin-right: 5px;
      }

      .icon2 {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }

  .CaseBanner {
    margin-top: 36px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}