.OurHonorPc {
  padding: 100px 38px 0 126px;
  display: flex;

  :global {
    .content {
      width: 25%;
      margin-right: 26px;
      transition-property: all;

      .outBlock {
        height: 488px;
        margin-bottom: 31px;
      }
    }

    .content1 {
      // margin-top: 300px;
      transition: all 10ms;
      will-change: transform;
    }

    .content2 {
      // margin-top: 30px;
    }

    .content3 {
      // margin-top: 230px;
    }
  }

}

.outCart {
  position: relative;
  width: 412px;
  height: 488px;
  margin-bottom: 31px;

  :global {
    .honorImg {
      width: 43px;
      height: 75px;
      position: absolute;
      right: 29px;
    }

    .cart {
      // width: 405px;
      height: 488px;
      background-color: black;
      position: absolute;
      border-radius: 4px;
      backface-visibility: hidden;

      .name {
        width: 400px;
        height: 57px;
        position: absolute;
        top: 330px;
        left: 31px;
        right: 269px;
        font-size: 50px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 45px;
      }

      .english {
        position: absolute;
        top: 425px;
        left: 31px;
        width: 400px;
        height: 32px;
        font-size: 30px;
        font-family: 'RTWS ShangYa Demo';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 36px;
      }

      .egWord {
        width: 400px;
        position: absolute;
        top: 370px;
        left: 31px;
        font-size: 50px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 45px;
      }
    }
  }

}

.honer {
  margin-right: 26px;
  width: 408px;
  height: 408px;
  border-radius: 50%;
  background-color: #F38400;
  margin-bottom: 31px;
  position: relative;

  :global {
    .all {
      width: 232px;
      height: 98px;
      font-size: 45px;
      font-family: 'PingFang';
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
      // transform: rotate(20deg);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    .honorEg {
      // width: 133px;
      text-align: center;
      height: 57px;
      font-size: 36px;
      font-family: 'RTWS ShangYa Demo';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 36px;
      // transform: rotate(20deg);
      position: absolute;
      top: 80px;
      bottom: 0;
      right: 10px;
      left: 0;
      margin: auto;
    }

    .egWord {
      font-size: 60px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 45px;
      width: 270px;
      height: fit-content;
      transform: rotate(20deg);
      position: absolute;
      top: -10px;
      right: 0px;
      bottom: 0;
      left: 0;
      margin: auto;
      text-align: center;
    }
  }
}

.backHeight {
  width: 412px;
  height: 488px;

  :global {
    .backCard {
      position: absolute;
      width: 412px;
      height: 488px;
      background: #F38400;
      border-radius: 4px;
      padding: 39px 30px 0 22px;
      margin-bottom: 31px;
      backface-visibility: hidden;

      :global {
        .contain {
          width: 360px;
          height: 390px;
          font-size: 18px;
          // font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          overflow-y: scroll;
          scrollbar-color: rgba(255, 255, 255, 0.6) #F38400;
          scrollbar-width: thin;
        }
      }
    }
  }
}