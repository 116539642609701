
.bq_card_fadein{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  :global{
    .fadein_body{
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }
    .fadein_transition{
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      animation-duration: .5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      will-change: width;
      position: relative;
    }
  }
}