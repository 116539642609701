.ServiceScopePC {
  width: 100%;
  display: flex;
  margin-top: -2px;
  border-top: 2px solid #fff;
  margin-bottom: 179px;

  .left {
    width: 566px;
    font-size: 70px;
    font-family: 'HanSerifSC';
    font-weight: 400;
    color: #F38400;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;

    .word {
      width: 420px;
      height: 100px;
      position: relative;
      transition: top .2s;
      left: 0;
    }
  }

  .centerCircle {
    width: 428px;
    border-right: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-self: center;
    border-bottom: 2px solid #fff;

    .circle {
      width: 100%;
      height: 428px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      font-size: 36px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      .word2 {
        width: fit-content;
        height: 34px;
        position: relative;
        transition: top .4s;
        left: 0;
      }
    }
  }

  .rightContent {
    width: 924px;
    border-bottom: 2px solid #fff;

    .outBox {
      .detaileContent {
        padding: 30px 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: 100%;
        width: 100%;
        position: relative;
        left: 0;
        top: 70px;
        opacity: 0;
        // transition: top .2s;
        // 动画持续时间
        animation-duration: .2s;
        // 动画速度曲线
        animation-timing-function: linear;
        // 动画次数
        animation-iteration-count: 1;
        // 动画结束后以保留最后一帧
        animation-fill-mode: forwards;
        will-change: top;

        .topTitle {
          font-size: 36px;
          font-family: 'PingFang';
          font-weight: 500;
          color: #FFFFFF;
        }

        .bottomTitle {
          font-size: 26px;
          color: #999999;
          width: 100%;

          p {
            text-align: left;
          }
        }
      }

      .detaileContentSingle {
        height: 428px;
        text-align: left;
      }

      .borders {
        width: 100%;
        height: 1px;
        background-color: #F9F9F9;
        will-change: width;
        width: 0;
        // 动画持续时间
        animation-duration: 1.5s;
        // 动画速度曲线
        animation-timing-function: linear;
        // 动画次数
        animation-iteration-count: 1;
        // 动画结束后以保留最后一帧
        animation-fill-mode: forwards;
      }
    }

    @keyframes wordTop {
      0% {
        opacity: 0;
        top: 100px;
      }

      50% {
        opacity: .1;
        top: 40px;
      }

      100% {
        top: 0;
        opacity: 1;
      }
    }

    @keyframes wordWidth {
      0% {
        width: 0;
        opacity: 0;
      }

      100% {
        width: 100%;
        opacity: 1;
      }
    }
  }
}