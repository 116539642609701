.TopServicePC {
  padding: 80px 0 70px 100px;

  .titleBox {
    // height: 100px;
    display: flex;
    align-items: flex-end;

    .tltle_ch {
      font-size: 73px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #FFFFFF;
      margin-right: 37px;
      // line-height: 70px;
    }

    .tltle_eg {
      font-size: 60px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 90px;

      a {
        font-size: 60px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 90px;
      }
    }
  }
}