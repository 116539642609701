.ServiceScopeMob{
  width: 100%;
  // display: flex;
  border-bottom: 1px solid #F9F9F9;
  .topBanner{
    padding: 40px 0 0 15px;
  }
  .rightContent{
    width: 100%;
    border-top: 1px solid #F9F9F9;
    .outBox{
      .detaileContent{
        padding: 18px 10px 18px 15px;
        height: 100%;
        width: 100%;
        position: relative;
        left: 0;
        top: 50px;
        opacity: 0;
        // transition: top .2s;
        // 动画持续时间
        animation-duration: .2s;
        // 动画速度曲线
        animation-timing-function: linear;
        // 动画次数
        animation-iteration-count: 1;
        // 动画结束后以保留最后一帧
        animation-fill-mode: forwards;
        will-change: top;
        .topTitle{
          font-size: 18px;
          font-family: 'PingFang';
          font-weight: 500;
          color: #FFFFFF;
          line-height: 21px;
        }
        .bottomTitle{
          margin-top: 10px;
          font-size: 13px;
          line-height: 21px;
          color: #999999;
        }
      }
      .borders{
        width: 100%;
        height: 1px;
        background-color: #F9F9F9;
        will-change: width;
        width: 0;
        // 动画持续时间
        animation-duration: 1.5s;
        // 动画速度曲线
        animation-timing-function: linear;
        // 动画次数
        animation-iteration-count: 1;
        // 动画结束后以保留最后一帧
        animation-fill-mode: forwards;
      }
    }
    @keyframes wordTop {
      0%{
        opacity: 0;
        top: 15px;
      }
      50%{
        opacity: .1;
        top: 10px;
      }
      100%{
        opacity: 1;
        top: 0;
      }
    }
    @keyframes wordWidth {
      0%{
        width: 0;
        opacity: 0;
      }
      100%{
        width: 100%;
        opacity: 1;
      }
    }
  }
}