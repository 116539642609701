.WordContentPC{
  height: auto;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
  .leftTitle{
    width: 435px;
    display: inline-block;
    vertical-align: middle;
    font-size: 34px;
    font-family: 'PingFang';
    font-weight: bold;
    color: #FFFFFF;
    user-select: none;
    transition: all .3s ease-out;
    transform: translateY(60px);
    opacity: 0;
  }
  .words{
    font-size: 20px;
    font-family: 'PingFang';
    font-weight: 400;
    color: #FFFFFF;
    line-height: 38px;
    transition: all .3s ease-out;
    transform: translateY(100px);
    opacity: 0;
    width: 1000px;
    height: auto;
  }
  .transTop {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
    
}