.leaderTalkPc {
  padding: 100px 0;

  :global {
    .h2 {
      height: 204px;
      box-sizing: border-box;
      padding-top: 64px;
      line-height: 140px;
      font-size: 68px;
      padding-left: 95px;
      overflow: hidden;
      display: flex;

      .aboutbq {
        text-indent: 33px;
        font-size: 58px;
        line-height: 140px;
        transform: translateY(13px);
        color: #F38400;
        font-family: 'HanSerifSC';
      }
    }

    .border {
      height: 2px;
      background-color: #fff;
    }

    .content {
      display: flex;
      justify-content: space-between;
      padding: 104px 0 0 137px;

      .leaderPic {
        width: 12rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      .introduce {
        width: 874px;
        padding: 0 157px 0 104px;
        background-image: url('../../../../assets/img/back_pc.png');
        background-size: 874px 100%;

        .name {
          font-size: 69px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 104px;
          margin-bottom: 43px;
          transition: all .5s;
          transform: translateY(60px);
          opacity: 0;
        }

        .position {
          font-size: 26px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #F38400;
          line-height: 60px;
          transition: all .3s;
          transform: translateY(100px);
          opacity: 0;
          margin-bottom: 47px;
          transition-delay: .2s;
        }

        .bqTarget {
          font-size: 24px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 45px;
          transition: all .3s;
          transform: translateY(60px);
          transition-delay: .5s;
          opacity: 0;
        }
      }
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }
  }
}