// 六边形
.Hexagon {
  width: 318px;
  height: 550px;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  // z-index: 99;
  // border: 1px solid darkblue;

  :global {
    .one {
      width: 318px;
      height: 550px;
      border-top: 6px solid #fff;
      border-bottom: 6px solid #fff;
      box-sizing: border-box;
      position: relative;
      transform: rotate(15deg);
      background: #000;

      &::before {
        content: '';
        width: 625px;
        height: 6px;
        background: #fff;
        position: absolute;
        top: -3px;
        right: 3px;
        z-index: 1000;
        transform: rotate(-60deg);
        transform-origin: top right;
      }
    }

    .two {
      position: absolute;
      width: 318px;
      height: 550px;
      left: 0;
      top: 0;
      // transform: translate(-50%,-50%);
      transform: rotate(75deg);
      border-top: 6px solid #fff;
      border-bottom: 6px solid #fff;
    }

    .three {
      position: absolute;
      width: 318px;
      height: 550px;
      left: 0;
      top: 0;
      // transform: translate(-50%,-50%);
      transform: rotate(315deg);
      border-top: 6px solid #fff;
      border-bottom: 6px solid #fff;
    }

    .first_text {
      width: 275px;
      height: 625px;
      // border: 1px solid firebrick;
      top: 0;
      right: 0;
      transform: rotate(45deg) translateX(85px);
      transform-origin: top right;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .letter_box {
        width: 100;
        height: 70%;
        overflow: hidden;
        transform: rotate(-45deg) translate(50px, 40px);
      }
    }

    .last_text {
      width: 275px;
      height: 625px;
      // border: 1px solid firebrick;
      top: 0;
      right: 0;
      transform: rotate(45deg) translateX(357px);
      transform-origin: top right;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .letter_box {
        width: 100;
        height: 70%;
        overflow: hidden;
        transform: rotate(-45deg) translate(30px, 27px);
      }
    }
  }
}

// 正方形
.Square {
  width: 560px;
  height: 560px;
  box-sizing: border-box;
  border: 6px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  // z-index: 99;

  &::before :local {
    content: '';
    display: block;
    width: 786px;
    height: 6px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  :global {
    .first_text {
      width: 786px;
      height: 393px;
      // border: 1px solid green;
      top: 0;
      left: 0;
      transform: rotate(45deg);
      transform-origin: top left;
      overflow: hidden;

      .letter_box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // border: 1px solid gold;
        transform: rotate(-45deg) translateY(-393px);
        transform-origin: top right;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .letter {
          width: 1em;
          height: 360px;
          text-align: center;
          line-height: 360px;
          display: block;
          transform: translate(50px, 25px);
        }
      }
    }

    .last_text {
      width: 786px;
      height: 393px;
      // border: 1px solid green;
      top: 0;
      left: 0;
      transform: rotate(45deg) translateY(-100%);
      transform-origin: top left;
      overflow: hidden;
      z-index: 1;

      .letter_box {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: -51%;
        overflow: hidden;
        // border: 1px solid gold;
        transform: rotate(-45deg);
        transform-origin: top right;
        display: flex;
        justify-content: center;
        align-items: center;

        .letter {
          width: 100%;
          height: 100%;
          text-align: right;
          line-height: 360px;
          display: block;
          transform: translateX(-30px);
        }
      }
    }
  }
}

// 三角形1
.TriangleLeft {
  width: 0;
  height: 0;
  border-bottom: 627px solid #fff;
  border-left: 627px solid transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-314px, -316px);
  background-color: #000;
  // z-index: 99;

  :global {
    .triangle_bg {
      width: 0;
      height: 0;
      border-bottom: 606px solid #000;
      border-left: 606px solid transparent;
      transform: translate(-612px, 15px);
      position: relative;

      &::after :local {
        position: absolute;
        content: '';
        display: block;
        width: 434px;
        height: 6px;
        background: #fff;
        top: 606px;
        transform: rotate(45deg);
        transform-origin: right bottom;
        left: -432px;
      }
    }

    .first_text {
      width: 428px;
      height: 428px;
      // border: 1px solid red;
      text-align: center;
      overflow: hidden;
      top: 318px;
      left: -312px;
      transform: rotate(45deg);
      transform-origin: top left;

      .letter_box {
        width: 100%;
        height: 80%;
        overflow: hidden;
        // border: 1px solid gold;
        transform: rotate(-45deg) translate(-50%, -38px);
        transform-origin: top left;

        .letter {
          width: 428px;
          height: 428px;
          text-align: center;
          line-height: 428px;
          display: block;
        }
      }
    }

    .last_text {
      width: 428px;
      height: 428px;
      // border: 1px solid greenyellow;
      text-align: center;
      overflow: hidden;
      top: 314px;
      left: -308px;
      transform: rotate(-45deg);
      transform-origin: top left;

      .letter_box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // border: 1px solid gold;
        position: relative;
        top: 0;
        right: 0;
        transform: rotate(45deg) translate(-48%, 0);
        transform-origin: center center;

        .letter {
          width: 428px;
          height: 428px;
          text-align: right;
          line-height: 428px;
          display: block;
        }
      }
    }
  }
}

// 三角形2
.TriangleRight {
  width: 0;
  height: 0;
  border-top: 627px solid #fff;
  border-right: 627px solid transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-310px, -310px);
  background-color: #000;
  // z-index: 99;

  :global {
    .triangle_bg {
      width: 0;
      height: 0;
      border-top: 606px solid #000;
      border-right: 606px solid transparent;
      transform: translate(6px, -621px);
      position: relative;

      &::after :local {
        position: absolute;
        content: '';
        display: block;
        width: 434px;
        height: 6px;
        background: #fff;
        top: -609px;
        left: 0;
        transform: rotate(45deg);
        transform-origin: top left;
      }
    }

    .first_text {
      width: 428px;
      height: 428px;
      // border: 1px solid red;
      text-align: center;
      overflow: hidden;
      top: -316px;
      left: -121px;
      transform: rotate(45deg);
      transform-origin: top right;

      .letter_box {
        width: 71%;
        height: 100%;
        overflow: hidden;
        // border: 1px solid gold;
        position: relative;
        top: 50%;
        left: 50%;
        transform: rotate(-45deg) translate(0, -50%);
        transform-origin: top left;

        .letter {
          width: 428px;
          height: 428px;
          text-align: left;
          line-height: 428px;
          display: block;
        }
      }
    }

    .last_text {
      width: 428px;
      height: 428px;
      // border: 1px solid greenyellow;
      text-align: center;
      overflow: hidden;
      top: -318px;
      left: 309px;
      transform: rotate(-135deg);
      transform-origin: top left;

      .letter_box {
        width: 100%;
        height: 100%;
        overflow: hidden;
        // border: 1px solid red;
        transform: rotate(135deg) translate(40px, 140px);
        transform-origin: center center;

        .letter {
          width: 428px;
          height: 428px;
          text-align: center;
          line-height: 428px;
          display: block;
        }
      }
    }
  }
}

// 圆形
.Circular {
  width: 614px;
  height: 614px;
  border: 6px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  // z-index: 99;
  border-radius: 50%;
  overflow: hidden;

  :global {
    .first_text {
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      width: 300px;
      text-align: center;
    }

    .last_text {
      top: 50%;
      left: 50%;
      width: 313px;
      height: 313px;
      border: 6px solid #fff;
      box-sizing: border-box;
      text-align: left;
      line-height: 313px;
      transform: rotate(-45deg) translate(-3px, -3px);
      transform-origin: 0 0;
      overflow: hidden;

      .letter {
        transform: rotate(45deg) translate(-40px, -20px);
        display: block;
      }
    }
  }
}

// 正方形旋转45度
.SquareRotate {
  width: 520px;
  height: 520px;
  box-sizing: border-box;
  border: 6px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center center;
  background-color: #000;
  // z-index: 99;

  :global {
    .first_text {
      height: 520px;
      line-height: 520px;
      transform: rotate(-45deg) translate(10px, 30px);
      transform-origin: center center;
    }

    .last_text {
      height: 520px;
      line-height: 520px;
      transform: rotate(-45deg) translate(75%, 30px);
      transform-origin: center center;
    }
  }
}

// 长方形
.SquareBoth {
  width: 640px;
  height: 355px;
  border: 6px solid #fff;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000;
  // z-index: 99;
  overflow: hidden;
  display: flex;
  align-items: center;

  &::before :local {
    content: '';
    display: block;
    width: 6px;
    height: 355px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-3px);
  }

  :global {
    .oneLetter {
      width: 312px;
      height: 355px;
      overflow: hidden;
    }

    .first_text {
      position: relative;
      top: -40px;
      text-align: center;
      width: 400px;
      transform: rotate(-45deg) translateX(-45px);
      transform-origin: center center;
    }

    .last_text {
      position: relative;
      text-align: center;
      flex-grow: 1;
      width: 50%;
      transform: rotate(-45deg);
      transform-origin: center center;
    }
  }
}

// 文字
.text {
  position: absolute;
  font-size: 360px;
  line-height: 1;
  font-family: 'HanSerifSC';
  width: 360px;
  height: 360px;
  overflow: hidden;
}