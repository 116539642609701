
.bqBlock{
  width: 100%;
  height: calc(100vh - 350px);
  object-fit: cover;
  display: block;
}
.bqImage{
  width: 100%;
  height: calc(100vh - 350px);
  object-fit: fill;
  display: block;
  font-family: 'object-fit: fill';
}