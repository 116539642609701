.newsList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 72px 110px;

  :global {
    .imgBox {
      border-radius: 8px;
      overflow: hidden;
      display: block;
    }

    .img {
      width: 100%;
      object-fit: contain;
      transition: all .3s ease-out;
      transform: scale(1);
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    .cardFoot {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      overflow: hidden;
      font-size: 18px;
      line-height: 34px;
      margin-top: 20px;

      span {
        margin-right: 50px;
      }
    }
  }
}