.SmallBannerMob {
  .oneLine {
    display: flex;
    align-items: center;
  }

  .contentTitle {
    height: 73px;

    .title_ch {
      font-size: 30px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #FFFFFF;
      margin-right: 20px;
    }

    .title_eg {
      font-size: 15px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
    }

    .title_en {
      font-size: 30px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 28px;
      height: 23px;
      margin-bottom: 25px;
      line-height: 38px;
    }
  }
}