.GrowthMob {
  padding-top: 10px;
  width: 100%;

  .h2 {
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    // padding-top: 64px;
    line-height: 19px;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
    border-bottom: 1px solid #fff;
    will-change: width opacity;
    white-space: nowrap;
  }

  .title_zh {
    font-size: 21px;
    // font-family: 'PingFang';
    line-height: 19px;
    font-weight: bold;
    user-select: none;
    color: #F38400;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 15px 0 15px !important;
  }

  .panel_header {
    height: 82px;
    padding: 0 15px 0 15px;
    line-height: 82px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftWord {
      height: 52px;
      line-height: 52px;

      .panel_zh {
        height: 17px;
        vertical-align: middle;
        width: 300px;
        font-size: 18px;
        user-select: none;
        transition: opacity .3s;
        will-change: opacity;
      }

      .panel_en {
        height: 13px;
        vertical-align: middle;
        width: 300px;
        font-size: 12px;
        color: #F38400;
        user-select: none;
        font-family: 'HanSerifSC';
        transition: opacity .3s;
        will-change: opacity;
      }
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 1px solid rgba($color: #fff, $alpha: .4);
    padding: 34px 0 39px 0;
    overflow: hidden;
    white-space: nowrap;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .cont_img {
      width: 100%;
      height: 267px;
      object-fit: cover;
      display: inline-block;
      vertical-align: middle;
      font-family: 'object-fit: cover';
      margin-bottom: 39px;
    }

    .cont {
      margin: 0 auto;
      width: 319px;
      word-break: break-all;
      vertical-align: middle;
      white-space: normal;
      font-size: 13px;
      font-family: PingFang;
      font-weight: 400;
      color: #959595;
      line-height: 21px;
    }
  }

  @keyframes panel_cont_open {
    0% {
      transform: rotateX(90deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  @keyframes panel_cont_close {
    0% {
      transform: rotateX(0);
    }

    100% {
      transform: rotateX(0deg);
    }
  }
}