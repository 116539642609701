.sense {
  width: 100%;
  // height: 250px;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  overflow: hidden;
  background: url(../../../../assets/img/-s-mob.png) 0 0 no-repeat;

  :global {
    .title {
      color: #F38400;
      font-size: 21px;
      line-height: 19px;
      position: absolute;
      top: 60px;
      font-weight: bold;
      // font-family: PingFang;
      opacity: 0;
      left: 15px;
      transition: all .1s;
    }

    .title_active {
      opacity: 1;
      top: 35px;
    }

    .list {
      width: 100%;
      padding: 0 15px;
      // height: 145px;
      display: flex;
      margin-top: 92px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      // padding: 0 26px 0 15px;
      li {
        display: flex;
        flex-direction: column;
        justify-items: center;
        width: 50%;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        height: 70px;
        transition: all ease-in-out;
        opacity: 0;
        text-align: center;
        // margin-right: 6px;
        min-width: 75px;
        margin-bottom: 5px;

        // &:nth-child(3n){
        //   margin-right: 0;
        // }
        &.li_active {
          top: -35px;
          opacity: 1;
        }

        .zhl {
          font-size: 18px;
          line-height: 53px;
          font-weight: 500;
          font-family: 'PingFang';
        }

        .cnl {
          font-size: 12px;
          line-height: 11px;
          color: #F38400;
          font-family: 'HanSerifSC';
        }
      }
    }

    .list.special {
      li:nth-child(1) {
        width: 2.8rem;
      }
    }
  }
}