.RotationMob{
  position: relative;
  margin-bottom: 78px;
  height: 710px;
  :global{
    .circles{
      position: absolute;
      bottom: -50px;
    }
    .blockItem{
      padding-left: 15px;
      padding-right: 15px;
      height: 650px;
      position: relative;
      .item1{
        width: 293px;
        height: 275px;
        margin-top: 40px;
        .imgScial{
          width: 100%;
          height: 206px;
          object-fit: cover;
        }
        .describe{
          margin-top: 16px;
          font-size: 12px;
          font-family: PingFang;
          font-weight: 400;
          color: #FFFFFF;
        }
        .date{
          margin-top: 16px;
          font-size: 11px;
          font-family: PingFang;
          font-weight: bold;
          color: #FFFFFF;
        }
        &:last-child{
          position: absolute;
          right: 15px;
        }
      }
    }
    .leftButton{
      position: absolute;
      left: 50px;
      bottom: 3px;
      cursor: pointer;
    }
    .rightButton{
      position: absolute;
      right: 50px;
      bottom: 3px;
      cursor: pointer;
    }
    .ant-carousel .slick-dots li{
      height:10px;
      width:10px;
      border:1px solid #fff;
      border-radius:50%;
    }
    .ant-carousel .slick-dots li button{
      height:10px;
      width:10px;
      border:1px solid #F38400;
      border-radius:50%;
    }
    .ant-carousel>.slick-dots.dotsss>li>button{
      height:10px;
      width:10px;
      border:1px solid #F38400;
      border-radius:50%;
    }
    .ant-carousel .slick-dots li.slick-active{
      height:10px;
      width:10px;
      border:1px solid #F38400;
      border-radius:50%;
      background-color: #F38400;
    }
    .ant-carousel .slick-dots li.slick-active button{
      background-color: #F38400;
    }
  }
}