.er {
  width: 100%;

  :global {
    .header {
      display: flex;
      height: 214px;
      overflow-y: hidden;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        width: 1219px;
        border-right: 1px solid #fff;

        .zh {
          text-indent: 100px;
          font-size: 60px;
          top: 200px;
          left: 0;
          transition: top .3s ease-in;
          position: relative;
        }

        .cn {
          text-indent: 100px;
          font-size: 30px;
          font-weight: 400;
          color: #F38400;
          font-family: 'HanSerifSC';
          top: 200px;
          left: 0;
          transition: top .3s .1s ease-in;
          position: relative;
        }

        .en_big {
          color: #F38400;
          font-size: 50px;
          font-family: 'HanSerifSC';
          font-weight: 400;
        }
      }

      .right {
        height: 100%;
        flex-grow: 1;
      }
    }

    .content {
      display: flex;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      overflow-y: hidden;

      .left {
        display: flex;
        flex-direction: column;
        width: 1219px;
        box-sizing: border-box;
        padding: 41px 239px 41px 100px;
        border-right: 1px solid #fff;
        position: relative;
        top: 0;
        left: 0;

        .er {
          font-size: 42px;
          color: #F38400;
          line-height: 116px;
          top: 200px;
          left: 0;
          transition: top .3s .15s ease-in;
          position: relative;
        }

        .culture {
          font-size: 20px;
          line-height: 38px;
          top: 200px;
          left: 0;
          transition: top .3s .2s ease-in;
          position: relative;

          p {
            min-height: 38px;
            display: block;
          }
        }
      }

      .right {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .er {
          width: 296px;
          height: 436px;
          position: absolute;
          top: 35%;
          transition: top .3s ease-in;
        }
      }
    }

    .active {
      top: 0 !important;
    }

    .activeEr {
      top: 15% !important;
    }
  }
}