.LeaderMob {
  .block {
    width: 100%;
    // height: 810px;
    display: flex;
    flex-direction: column;

    .introduce_eg {
      padding-left: 10px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 98px;
      width: 100%;
      border-bottom: 1px solid #FFFFFF;

      .one_eg {
        font-size: 23px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 30px;
        font-style: italic;
        text-align: center;
      }

      .two_eg {
        font-size: 20px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .introduce {
      height: 98px;
      width: 100%;
      border-bottom: 1px solid #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      .one {
        width: 280px;
        height: 30px;
        font-size: 31px;
        font-family: 'HanSansHWK';
        font-weight: normal;
        color: #F38400;
        text-align: center;
        // margin-right: 15px;
      }

      .two {
        width: 53px;
        height: 24px;
        font-size: 26px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
      }

    }

    .ant-collapse-borderless>.ant-collapse-item {
      border-bottom: 1px solid #F9F9F9;
      // border-bottom: none;
    }

    .ant-collapse-content-box {
      padding-left: 10px;
    }

    .panel_box {
      height: 549px;
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar-thumb {
        background-color: #F38400;
      }

      .ant-collapse-header {
        height: 46px;
        padding-left: 10px;
        padding-right: 0;
      }

      .panel_header {
        display: flex;
        height: 22px;
        line-height: 46px;
        align-items: center;

        .time {
          width: 68px;
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          font-family: 'PingFang';
          font-weight: 500;
          color: #FFFFFF;
          user-select: none;
        }

        .time {
          width: unset;
        }

        .word {
          display: inline-block;
          user-select: none;
          font-size: 13px;
          font-family: 'PingFang';
          font-weight: 500;
          color: #999999;
          align-items: center;
          white-space: nowrap;
        }
      }

      .panel_cont {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

        .cont_img {
          width: 346px;
          font-size: 12px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #959595;
          line-height: 19px;
          margin-bottom: 25px;
          margin-left: 16px;
        }

        .leaderImg {
          background-color: #000;
          width: 123px;
          height: 145px;
          object-fit: cover;
        }
      }
    }

  }
}