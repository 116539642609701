.work{
  width: 100%;
  position: relative;
  :global{
    .title :local{
      font-size: 42px;
      color: #F38400;
      line-height: 62px;
      margin: 70px 0 0 98px;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      
      @keyframes title_open {
        0% {
          transform: translateY(30px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes title_close {
        0% {
          transform: translateY(0);
          opacity: 1;
        }
        100% {
          transform: translateY(30px);
          opacity: 0;
        }
      }
    }
    .content{
      width: 100%;
      height: 920px;
      position: relative;
      overflow: hidden;
      .img1, .img2{
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        object-fit: cover;
        transition: width .3s ease-in-out, height .3s ease-in-out;
      }
      .img1{
        top: 122px;
        left: 253px;
      }
      .img2{
        top: -10px;
        right: 200px;
      }
      .img1_active{
        width: 566px;
        height: 685px;
        opacity: 1;
      }
      .img2_active{
        opacity: 1;
        width: 466px;
        height: 585px;
      }
      .text{
        position: absolute;
        left: 752px;
        font-size: 172px;
        line-height: 172px;
        z-index: 2;
        .letter :local{
          animation-duration: 1s;
          animation-timing-function: ease-out;
          animation-iteration-count: 1;
          animation-fill-mode: both;
          display: inline-block;
          @keyframes landIn {
            from {
              opacity: 0;
              transform: translateY(-20%);
            }
          
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
      .text:nth-child(1) {
        top: 290px;
      }
      .text:nth-child(2) {
        top: 462px;
      }
      .text:nth-child(3) {
        top: 634px;
      }
    }
  }
}
