.Map{
  width: 100%;
  :global{
    .trans_box{
      width: 100%;
      transition: height .3s ease-out;
      overflow: hidden;
      .img{
        width: 100%;
        // height: 650px;
      }
    }
  }
}