.HonoraryQualiPC {
  .outside {
    &:first-child {
      .timeBlock {
        border: none !important;
      }
    }
  }

  .honorBox {
    height: 1556px;
    border-bottom: 2px solid #fff;
    width: 100%;
    overflow: hidden;
    display: flex;

    .timeBlock {
      height: 1556px;
      cursor: pointer;
      display: flex;
      transition: width .3s;
      position: relative;
      border-left: 2px solid #fff;

      .blockOne {
        text-align: center;
        font-size: 34px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        position: relative;
        position: absolute;
        transition: all .3s;
        top: 0;
        transform: rotate(90deg) translateX(50%);
        transform-origin: center center;
        left: 0;
        font-family: 'HanSerifSC';
      }

      .openblockOne {
        top: 58px;
        transform: rotate(0) translateX(0);
        transition: all .3s;
        transform-origin: center center;
        left: 79px;
        font-size: 134px;
      }

      .closeBlockOne {
        display: none;
      }

      .showOpen {
        overflow: hidden;
        transition: all .5s;
        width: 1200px;
        height: auto;
        padding-left: 79px;

        // position: relative;
        .detaile {
          overflow: hidden;
          // position: absolute;
          // top: 256px;
          margin-top: 200px;
          font-size: 24px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #959595;
          line-height: 42px;
        }

        .imgItem {
          width: 474px;
          height: 371px;
          object-fit: cover;
          margin-top: 70px;
          margin-bottom: 30px;
          // position: absolute;
          // bottom: 56px;
        }
      }
    }
  }
}