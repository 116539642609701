.er {
  width: 100%;

  :global {
    .header {
      display: flex;
      height: 98px;
      overflow-y: hidden;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        .zh {
          text-indent: 15px;
          font-size: 30px;
          top: 35px;
          left: 0;
          transition: top .3s ease-in;
          position: relative;
        }

        .cn {
          text-indent: 17px;
          font-size: 15px;
          color: #F38400;
          font-family: 'HanSerifSC';
          top: 35px;
          left: 0;
          transition: top .3s .1s ease-in;
          position: relative;
        }

        .en {
          font-size: 25px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      overflow-y: hidden;

      .left {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        padding: 35px 12px 30px 15px;
        border-bottom: 1px solid #fff;
        position: relative;
        top: 0;
        left: 0;

        .er {
          font-size: 21px;
          color: #F38400;
          line-height: 19px;
          top: 50px;
          left: 0;
          transition: top .3s .15s ease-in;
          position: relative;
        }

        .culture {
          margin-top: 28px;
          font-size: 12px;
          line-height: 19px;
          top: 50px;
          left: 0;
          transition: top .3s .2s ease-in;
          position: relative;

          p {
            min-height: 18px;
            display: block;
          }
        }
      }

      .right {
        height: 304px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .er {
          width: 138px;
          height: 208px;
          position: absolute;
          top: 35%;
          transition: top .3s ease-in;
        }
      }
    }

    .active {
      top: 0 !important;
    }

    .activeEr {
      top: 15% !important;
    }
  }
}