.form {
  position: relative;
  width: 100%;
  padding: 35px 15px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  :global {
    .left {
      display: flex;

      .zh {
        font-size: 30px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        margin-right: 20px;
      }

      .cn {
        font-size: 15px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 28px;
      }
    }

    .right {
      margin-top: 44px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search_text {
          font-size: 14px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 33px;
        }

        .search_icon {
          font-size: 12px;
          width: 12px;
          height: 12px;
          color: #F38400;
          cursor: pointer;
        }
      }

      .input {
        border: none;
        border-bottom: 1px solid rgba($color: #fff, $alpha: .4);
        width: 100%;
        background-color: transparent;
        outline: none;
        caret-color: #fff;
        box-sizing: border-box;
        height: 19px;
        line-height: 19px;
        font-size: 14px;
        color: #fff;
      }
    }

    .types {
      position: absolute;
      top: -66px;
      right: 100px;
      height: 66px;
      width: 350px;
      display: flex;
      justify-content: space-between;

      .type {
        width: 123px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        color: #999999;
        cursor: pointer;
      }

      .type_active {
        background-color: #F38400;
        color: #fff;
        border-radius: 21px;
      }
    }
  }
}