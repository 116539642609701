.ContentBlockPC {
  padding: 128px 226px 128px 100px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topTitle {
      width: 730px;

      // margin-top: 90px;
      .topPart {
        .outBlock {
          margin-top: 88px;

          .brief {
            line-height: 88px;
          }

          .contentWords {
            font-size: 18px;
            font-family: 'PingFang';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 42px;
            overflow: hidden;
            height: 0;
            animation-duration: .5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            will-change: height;
          }
        }
      }

      .contactUs {
        margin-top: 75px;
        width: 188px;
        height: 55px;
        background: #F38400;
        border-radius: 28px;
        text-align: center;
        line-height: 55px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }

      .zh {
        font-family: 'AlibabaPuHuiTi';
      }

      .cn {
        font-family: 'HanSerifSC';
      }
    }

    .graph {
      width: 626px;
      position: relative;
      height: 626px;
      overflow: hidden;
    }
  }
}