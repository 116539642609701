.CenterPictureMob {
  // padding: 0 15px;
  margin-bottom: 100px;

  .pictureInform {
    padding-left: 0;

    .topPhoto {
      width: 100%;
      height: 179px;
      // margin-top: 63px;
      display: flex;

      .outside {
        width: 109px;
        height: 115px;
        margin-right: 10px;
        object-fit: cover;
        overflow: hidden;

        .leftPhoto {
          width: 0;
          height: 0;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          object-fit: cover;
          transition: all .3s ease-out;

          &:hover {
            transform: scale(1.05);
            transition-property: all;
          }
        }
      }

      .outsideRight {
        width: 227px;
        height: 179px;
        overflow: hidden;
        object-fit: cover;

        .rightPhoto {
          transition: all .3s ease-out;
          width: 0;
          height: 0;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;

          &:hover {
            transform: scale(1.05);
            transition-property: all;
          }
        }
      }
    }

    .bigPhoto {
      padding-left: 15px;
      margin-top: 35px;
      width: 330px;
      height: auto;
      object-fit: cover;
      // overflow: hidden;
      margin-left: -15px;

      .photoItem {
        &:hover {
          transform: scale(1.05);
          transition-property: all;
        }
      }
    }
  }
}