.searchList {
  :global {
    .title {
      display: flex;
      padding: 0 127px 30px 99px;
      border-bottom: 2px solid #fff;
      justify-content: space-between;

      .titles {
        display: flex;

        .ch {
          font-size: 73px;
          font-family: 'PingFang';
          font-weight: 500;
          margin-right: 40px;
        }

        .eg {
          font-size: 60px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #F38400;
          line-height: 130px;
        }
      }

      .num {
        font-size: 42px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 130px;
      }
    }

    .bottom {
      padding: 100px;

      .block {
        margin-bottom: 80px;

        .oneTitle {
          padding-bottom: 26px;
          border-bottom: 1px solid #fff;
          display: flex;
          margin-bottom: 80px;

          .ch {
            font-size: 36px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #FFFFFF;
            margin-right: 21px;
          }

          .eg {
            font-size: 30px;
            font-family: 'HanSerifSC';
            font-weight: 400;
            color: #F38400;
            line-height: 65px;
          }
        }

        .list {
          display: flex;
          flex-direction: column;

          .listBlock {
            cursor: pointer;
            display: flex;
            margin-bottom: 35px;
            align-items: center;

            .right {
              width: 17px;
              height: 11px;
              color: #fff;
              opacity: 0.6;
              margin-right: 32px;
            }

            .word {
              font-size: 26px;
              font-family: 'PingFang';
              font-weight: 500;
            }

            &:hover {
              color: #F38400;
            }
          }
        }
      }
    }

    .noResult {
      width: 100%;
      margin: auto;
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-family: 'HanSerifSC';
      font-weight: 400;
    }

    .more {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 221px;
      height: 56px;
      background: #F38400;
      border: 1px solid #F38400;
      border-radius: 28px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 221px;
    }
  }
}