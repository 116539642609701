.fourEcharts {
  padding: 100px 100px 100px 90px;

  ul.list {
    width: 100%;
    display: flex;
    justify-content: space-between;

    li {
      position: relative;
      width: 414px;
      height: 414px;
      animation-duration: 0s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      opacity: 1;
    }
  }
}

.big_circle {
  width: 414px;
  height: 414px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: content-box;
  transform: rotate(-22deg);

  .wrapper {
    width: 207px;
    height: 414px;
    position: absolute;
    top: 0;
    overflow: hidden;
    box-sizing: content-box;
  }

  .right {
    right: 0;
  }

  .left {
    left: 1px;
  }

  .circleProgress {
    width: 410px;
    height: 410px;
    border: 2px solid #000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    box-sizing: content-box;
    will-change: transform opacity;
    opacity: 0;
  }

  .rightcircle {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    right: 0;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    // animation: circleProgressLoad_right 1s linear infinite;
  }

  .leftcircle {
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 0;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  @keyframes circleProgressLoad_right {
    0% {
      transform: rotate(225deg);
      opacity: 1;
    }

    50% {
      transform: rotate(225deg);
      opacity: 1;
    }

    100% {
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  @keyframes circleProgressLoad_left {
    0% {
      transform: rotate(225deg);
      opacity: 1;
    }

    50% {
      transform: rotate(45deg);
      opacity: 1;
    }

    100% {
      transform: rotate(45deg);
      opacity: 1;
    }
  }
}

// 小圆
.small_circle {
  width: 304px;
  height: 304px;
  position: absolute;
  top: 56px;
  left: 56px;
  z-index: 2;
  box-sizing: content-box;
  background-color: transparent;
  transform: rotate(22deg);

  .small_circle_success {
    width: 280px;
    height: 280px;
    border: 12px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    box-sizing: content-box;
    z-index: -10;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
    will-change: transform opacity;
  }

  .wrapper {
    width: 152px;
    height: 304px;
    position: absolute;
    top: 0;
    overflow: hidden;
    box-sizing: content-box;
  }

  .right {
    right: 0;
  }

  .left {
    left: 1px;
  }

  .circleProgress {
    width: 280px;
    height: 280px;
    border: 12px solid #000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    box-sizing: content-box;
    will-change: transform;
    opacity: 0;
    z-index: 1;
  }

  .rightcircle {
    border-top: 12px solid #fff;
    border-right: 12px solid #fff;
    border-left: 12px solid #000;
    border-bottom: 12px solid #000;
    right: 0;
    animation-duration: 1s;
    animation-timing-function: linear;
    // animation-delay: .2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    // animation: circleProgressLoad_right 1s linear .2s infinite;
  }

  .leftcircle {
    border-top: 12px solid #000;
    border-right: 12px solid #000;
    border-bottom: 12px solid #fff;
    border-left: 12px solid #fff;
    left: 0;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    // animation: circleProgressLoad_left 1s linear .2s infinite;
  }

  @keyframes circleProgressLoad_right {
    0% {
      transform: rotate(225deg);
      opacity: 1;
    }

    50% {
      transform: rotate(225deg);
      opacity: 1;
    }

    100% {
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  @keyframes circleProgressLoad_left {
    0% {
      transform: rotate(225deg);
      opacity: 1;
    }

    50% {
      transform: rotate(45deg);
      opacity: 1;
    }

    100% {
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  @keyframes circleProgressLoad_success {
    0% {
      z-index: 0;
      opacity: 0;
    }

    99% {
      z-index: 0;
      opacity: 0;
    }

    100% {
      z-index: 10;
      opacity: 1;
    }
  }
}

.circle_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 414px;
  height: 414px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 3;
  will-change: transform;

  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes open_cont {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .cont {
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .cont_top {
      font-size: 32px;
      line-height: 64px;
      display: flex;
      align-items: flex-end;

      .num {
        font-size: 56px;
        line-height: 64px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }

      .title {
        line-height: 56px;
        transform: scaleY(7px);
      }
    }

    .cont_zh {
      font-size: 24px;
      line-height: 28px;
    }

    .cont_en {
      color: #F38400;
      font-size: 24px;
      text-align: center;
      font-family: 'HanSerifSC';
    }

    .cont_eg {
      width: 160px;
      text-align: center;
    }
  }
}