.ClubListMob {
  padding-top: 50px;

  .h2 {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    border-bottom: 1px solid #fff;
    will-change: width opacity;
    white-space: nowrap;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 15px 22px 15px !important;
  }

  .panel_header {
    height: 82px;
    padding: 23px 15px 22px 15px;
    line-height: 82px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .wordContent {
      height: 39px;
      line-height: 39px;

      .panel_zh {
        vertical-align: middle;
        width: 270px;
        font-size: 21px;
        user-select: none;
        transition: opacity .3s;
        will-change: opacity;
        height: 21px;
        color: #999999;
        font-weight: 500;
        font-family: 'PingFang';
        line-height: 21px;
      }

      .panel_en {
        margin-top: 5px;
        vertical-align: middle;
        width: 270px;
        font-size: 12px;
        line-height: 13px;
        height: 13px;
        color: #F38400;
        user-select: none;
        font-family: 'HanSerifSC';
        transition: opacity .3s;
        will-change: opacity;
      }
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 1px solid rgba($color: #fff, $alpha: .4);
    padding: 10px 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .picture {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 35px;

      .cont_img {
        width: 196px;
        height: 120px;
        object-fit: cover;
        margin-right: 5px;
        vertical-align: middle;
        margin-top: 13px;
        font-family: 'object-fit: cover';
      }

      .cont_imgTwo {
        object-fit: cover;
        width: 141px;
        height: 120px;
        vertical-align: middle;
        font-family: 'object-fit: cover';
        margin-top: 13px;

        &:nth-child(2n+1) {
          object-fit: cover;
          width: 196px;
          height: 120px;
        }
      }
    }

    .cont {
      width: 100%;
      font-size: 13px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #959595;
      word-break: break-all;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }
  }

  @keyframes panel_cont_open {
    0% {
      transform: rotateX(90deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  @keyframes panel_cont_close {
    0% {
      transform: rotateX(0);
    }

    100% {
      transform: rotateX(0deg);
    }
  }
}