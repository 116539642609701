.IntroduceMob{
  .pictureInform{
    padding: 63px 15px 0 15px;
    box-sizing: content-box;
    .introduce{
      .leftInform{
        font-size: 30px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        margin-bottom: 33px;
        overflow: hidden;
      }
      .rightInform{
        font-size: 14px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        line-height: 23px;
        overflow: hidden;
      }
    }
  }
}