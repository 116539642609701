.twoImage{
  width: 100%;
  display: flex;
  :global{
    .imageItem{
      width: 100%;
    }
    .img{
      width: 100%;
      object-fit: contain;
    }
  }
}