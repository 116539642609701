.newsList {
  display: flex;
  margin: 20px 8.5px;

  :global {
    .colList {
      position: relative;
      margin: 0 6.5px;
      width: 48%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      transition: animation .1s;
    }
  }
}

.front {
  width: 100%;
  height: 100%;
  padding: 24px 10px 25px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global {
    .frontTop {
      display: flex;
      justify-content: space-between;

      .num {
        font-size: 12px;
        font-family: 'HanSerifSC';
      }

      .icon {
        width: 27px;
        height: 27px;
      }
    }

    .frontBtm {
      width: 100%;
      display: flex;
      flex-direction: column;

      .zh {
        font-size: 30px;
        line-height: 18px;
      }

      .cn {
        margin-top: 9px;
        font-family: 'HanSerifSC';
      }

      .cn1 {
        font-size: 15px;
        line-height: 18px;
      }

      .zh1 {
        font-size: 23px;
        line-height: 23px;
      }
    }
  }
}

.back {
  width: 100%;
  height: 100%;
  // max-height: 300px;
  padding: 24px 15px 25px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #F38400;

  :global {
    .backTop {
      margin-bottom: 22px;

      .num {
        font-size: 12px;
        font-family: 'HanSerifSC';
      }
    }

    .backSize {
      font-size: 11px;
    }
  }
}