.ServiceMob {
	padding: 39px 0 51px 0;

	.h2 {
		height: 70px;
		box-sizing: border-box;
		font-size: 36px;
		font-family: 'HanSansHWK';
		padding-left: 14px;
		overflow: hidden;
		display: flex;

		.brandTitle {
			margin-right: 17px;
		}

		.brand {
			font-size: 30px;
			font-family: 'HanSerifSC';
			// line-height: 140px;
			transform: translateY(13px);
			color: #f38400;
		}
	}

	.border {
		height: 1px;
		background-color: #fff;
	}

	.player {
		height: calc(100vh - 50px);
		margin-top: 42px;
		position: relative;
		display: flex;
		width: 294px;
		height: 667px;
		margin: 0 auto;
		z-index: 1;

		.back {
			background-color: pink;
			width: 100px;
			height: 100px;
			position: absolute;
		}

		.ball {
			height: 100%;
			object-fit: contain;
			display: block;
		}

		.card {
			position: absolute;
			transition: opacity 1s;
			display: flex;

			&:hover {
				opacity: 1;
				transition: opacity 1s;
				// &>.inside{
				//   opacity: 1;
				// }
			}

			.inside {
				background-color: #000000;
				z-index: 5;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 325px;
				height: 257px;
				border: 1px solid #f38400;

				.contain {
					position: absolute;
					left: 20px;
					padding-top: 9px;
					border-left: 1px solid #f38400;
					padding-left: 20px;
					width: 300px;
					font-size: 14px;
					font-family: 'HanSansHWK';
				}
			}

			.pos {
				z-index: 100;
				opacity: 0;
				position: absolute;
				border: 1px solid #fff;
				width: 130px;
				height: 120px;

				&:hover+.inside {
					opacity: 1;
				}
			}

			.pos0 {
				top: -50px;
				left: 20px;
			}

			.pos1 {
				top: -55px;
				left: 150px;
			}

			.pos2 {
				top: -50px;
				left: 20px;
			}

			.pos3 {
				top: -50px;
				left: 150px;
			}

			.pos4 {
				left: 15px;
				top: -50px;
			}

			.pos5 {
				left: 140px;
				top: -50px;
			}
		}

		.card0 {
			top: 80px;
			left: -10px;
		}

		.card1 {
			top: 180px;
			left: 0px;
		}

		.card2 {
			top: 280px;
			left: -10px;
		}

		.card3 {
			top: 390px;
			left: 0px;
		}

		.card4 {
			top: 480px;
			left: -10px;
		}

		.card5 {
			top: 570px;
			left: 0px;
		}
	}

	.more {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
		margin-top: 44px;

		.knowMore {
			z-index: 98;
			display: inline-block;
			cursor: pointer;
			width: 120px;
			height: 35px;
			text-align: center;
			background: #f38400;
			border-radius: 18px;
			border: none;
			font-size: 13px;
			font-weight: bold;
			outline: none;
			line-height: 35px;
			color: #ffffff;
		}
	}
}