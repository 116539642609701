.JobList{
  padding: 0 100px;
  :global{
    .li{
      display: flex;
      padding: 78px 0 84px;
      justify-content: space-between;
      border-bottom: 1px solid #fff;
      &:last-child{
        border: none;
      }
      .name{
        font-size: 36px;
        color: #F38400;
        line-height: 56px;
      }
      .detail{
        width: 1000px;
        .post{
          .label{
            font-size: 22px;
            line-height: 42px;
          }
          .dl{
            margin-top: 23px;
            font-size: 18px;
            line-height: 32px;
          }
        }
        .nead{
          .order{
            display: flex;
            .jiantou{
              margin-top: 43px;
              margin-left: 10px;
              width: 31px;
              height: 31px;
              cursor: pointer;
            }
          }
          .label{
            font-size: 22px;
            line-height: 42px;
            display: flex;
            align-items: center;
            margin-top: 40px;
            cursor: pointer;
          }
          .dl_box1{
            position: relative ;
            top: 0;
            height: 0;
            overflow: hidden;
            font-size: 18px;
            opacity: 0.5;
            transition: all 0.5s;
          }
          .dl1{
            position: relative;
            top: 25px;
            transition: all 1s;
            max-height: 1100px; 
            overflow: visible;
            font-size: 18px;
            line-height: 32px;
            font-family: 'PingFang';
            font-weight: 500;
            opacity: 1;
          }
        }
      }
    }
  }
}