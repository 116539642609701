.fontSize {
  font-size: 140px;
  font-family: 'HanSerifSC';
  font-weight: 400;
  color: #FFFFFF;
}

.circular1 {
  position: absolute;
  top: -60px;
  right: -170px;
  width: 375px;
  height: 375px;

  :global {
    .round {
      width: 232px;
      height: 232px;
      border: 3px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;

      .text1 {
        position: absolute;
        left: 20px;
      }

      .boxCircle {
        width: 116px;
        height: 116px;
        border: 3px solid #fff;
        transform-origin: right top;
        transform: rotate(135deg);
        position: absolute;
        top: 86%;
        right: 13%;
        overflow: hidden;

        .text {
          transform: rotate(-135deg);
          position: absolute;
          right: 0px;
          top: -35px;
        }
      }
    }
  }
}

.squareBox1 {
  width: 375px;
  // height: 375px;
  position: absolute;
  top: -70px;
  left: 13px;

  :global {
    .cube {
      position: absolute;
      top: 95px;
      right: 50px;
      width: 232px;
      height: 232px;
      border: 3px solid #fff;

      &::after {
        content: '';
        transform: rotate(45deg);
        position: absolute;
        top: 113px;
        left: -47px;
        // transform-origin: right top;
        width: 323px;
        height: 3px;
        background-color: #fff;
      }

      .text {
        position: absolute;
        // border: 1px solid red;
        width: 110px;
        transform: rotate(-45deg) translateX(-110px);
        height: 320px;
        overflow: hidden;
        transform-origin: top left;

        .egOne {
          transform: rotate(45deg);
          position: absolute;
          top: 60px;
          left: 20px;
        }
      }

      .text1 {
        position: absolute;
        // border: 1px solid red;
        width: 100px;
        height: 222px;
        overflow: hidden;
        top: -40px;
        left: 120px;
      }
    }
  }
}

.square1 {
  width: 375px;
  height: 375px;
  position: absolute;
  top: -100px;
  left: -13px;

  :global {
    .leftGrap {
      width: 235px;
      height: 235px;
      border: 3px solid #fff;
      border-left-color: transparent;
      border-top-color: transparent;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-116px, -117px);
      overflow: hidden;

      .line {
        height: 3px;
        width: 161px;
        background-color: #fff;
        transform-origin: bottom right;
        transform: rotate(45deg);
        position: absolute;
        bottom: -2px;
        right: 3px;
      }

      .text {
        height: 222px;
        width: 90px;
        position: absolute;
        overflow: hidden;
        left: 70px;
        bottom: -65px;
      }

      .text1 {
        height: 150px;
        width: 150px;
        position: absolute;
        overflow: hidden;
        right: -65px;
        top: 44px;
        transform: rotate(45deg);

        .egOne {
          transform: rotate(-45deg);
          position: absolute;
          bottom: -53px;
          right: 50px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 332px;
        height: 3px;
        left: 2px;
        bottom: -2px;
        background-color: #ffffff;
        transform: rotate(-45deg);
        transform-origin: left center;
      }
    }
  }
}

.shape1 {
  width: 235px;
  height: 235px;
  position: absolute;
  top: 0px;
  left: 100px;

  // border: 1px solid pink;
  :global {
    .text {
      position: absolute;
      font-size: 140px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      width: 120px;
      height: 145px;
      overflow: hidden;

      .egOne {
        position: absolute;
        top: -30px;
        left: 43px;
      }
    }

    .text1 {
      position: absolute;
      font-size: 140px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      width: 108px;
      height: 125px;
      overflow: hidden;
      // border: 1px solid red;
      bottom: 10px;
      right: 30px;
      transform: rotate(7deg);
      border-bottom-right-radius: 48px;

      .egTwo {
        position: absolute;
        top: -45px;
        left: 20px;
        transform: rotate(-10deg);
      }
    }

    .egTwo {
      position: absolute;
      top: 10px;
      left: 80px;
    }

    .topGrap {
      transform: rotate(15deg);

      .one {
        width: 132px;
        height: 231px;
        margin: 0 auto;
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
      }

      .two {
        position: absolute;
        width: 132px;
        height: 231px;
        left: 51px;
        top: 0;
        transform: translate(-50%, -50%);
        transform: rotate(60deg);
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
      }

      .three {
        position: absolute;
        width: 132px;
        height: 231px;
        left: 51.5px;
        top: 0;
        transform: translate(-50%, -50%);
        transform: rotate(300deg);
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
      }
    }

    .bottomLine {
      z-index: 2;
      height: 3px;
      background-color: #fff;
      position: absolute;
      top: 116px;
      right: 116px;
      transform-origin: right center;
      transform: rotate(-45deg);
      width: 330px;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 1s;
      animation-fill-mode: both;
    }

    .bottomLine1 {
      height: 3px;
      background-color: #fff;
      position: absolute;
      top: 114px;
      right: -12px;
      transform-origin: top;
      transform: rotate(-45deg);
      width: 263px;
    }
  }
}

.oblong1 {
  width: 375px;
  height: 375px;
  position: absolute;
  top: -40px;
  left: 15px;

  :global {
    .topLine {
      height: 3px;
      background-color: #fff;
      position: absolute;
      top: 0px;
      right: 0px;
      transform-origin: right top;
      transform: rotate(-45deg);
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 1s;
      animation-fill-mode: both;
    }

    .rectangle1 {
      position: absolute;
      top: 115px;
      right: 40px;
      width: 280px;
      height: 140px;
      border: 3px solid #fff;
      transform: rotate(45deg);

      .text {
        transform: rotate(-45deg);
        position: absolute;
        top: -40px;
        left: 10px;
      }

      .text1 {
        transform: rotate(-45deg);
        position: absolute;
        right: 30px;
        bottom: -40px;
      }

      &::after {
        position: absolute;
        transform-origin: center;
        transform: rotate(90deg);
        top: 49.5%;
        left: 24.5%;
        content: '';
        width: 136px;
        height: 3px;
        background-color: #fff;
      }
    }

    .bottomLine {
      height: 3px;
      background-color: #fff;
      position: absolute;
      bottom: 85px;
      right: 85px;
      transform-origin: right top;
      transform: rotate(-134deg);
      // width: 320px;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 1s;
      animation-fill-mode: both;
    }
  }
}

.rhomb {
  width: 375px;
  height: 375px;
  position: absolute;
  top: -90px;
  left: 23px;

  :global {
    .lozenge {
      width: 202px;
      height: 202px;
      border: 3px solid #fff;
      position: absolute;
      left: 25%;
      top: 23%;
      transform: translate(-50%, -50%);
      transform: rotate(45deg);

      .text {
        transform: rotate(-45deg);
        position: absolute;
        left: 20px;
      }

      .text1 {
        transform: rotate(-45deg);
        position: absolute;
        left: 95px;
        top: -55px;
      }
    }
  }
}

.squareFlip {
  width: 375px;
  height: 375px;
  position: absolute;
  top: -70px;
  left: 13px;

  :global {
    .leftGrapFlip {
      position: absolute;
      right: 0px;
      width: 235px;
      height: 235px;
      border: 3px solid #fff;
      border-left-color: transparent;
      border-top-color: transparent;
      position: absolute;
      top: 26%;
      left: 25%;
      overflow: hidden;
      z-index: 99;
      transform: rotate(180deg);

      .line {
        width: 161px;
        height: 3px;
        background-color: #fff;
        transform: rotate(45deg);
        position: absolute;
        top: 170px;
        right: -23px;
      }

      .oneLetter {
        width: 235px;
        height: 235px;
        position: absolute;
        left: 48px;
        top: -50px;
        transform: rotate(-45deg);
        overflow: hidden;
        transform-origin: top right;

        .text {
          transform: rotate(-135deg);
          position: absolute;
          top: -45px;
          left: 8px;
        }
      }

      .text1 {
        transform: rotate(-135deg);
        position: absolute;
        top: 140px;
        left: 13px;
        // border: 1px solid red;
        height: 222px;
        width: 150px;
        overflow: hidden;

        .egOne {
          position: absolute;
          transform: rotate(-45deg);
          bottom: -65px;
          right: 0px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 332px;
        height: 3px;
        left: 2px;
        bottom: -2px;
        background-color: #ffffff;
        transform: rotate(-45deg);
        transform-origin: left center;
      }
    }
  }
}

@keyframes openLine {
  0% {
    width: 0;
  }

  100% {
    width: 530px;
  }
}

@keyframes openLines {
  0% {
    width: 0;
  }

  100% {
    width: 168px;
  }
}

@keyframes openLineOne {
  0% {
    width: 0;
  }

  100% {
    width: 200px;
  }
}

@keyframes openLineTwo {
  0% {
    width: 0;
  }

  100% {
    width: 123px;
  }
}

@keyframes openLineThree {
  0% {
    width: 0;
  }

  100% {
    width: 300px;
  }
}