.PageBqPC{
  /* 火狐 */
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;
  .page{
    padding: 45px 100px 29px 100px;
    position: relative;
    display: flex;
    justify-content: center;
    .pageItem{
      display: flex;
      .pageOne{
        width: 80px;
        height: 80px;
        color: #fff;
        cursor: pointer;
      }
    }
    .left{
      position: absolute;
      left: 100px;
      top: 45px;
      cursor: pointer;
    }
    .right{
      position: absolute;
      right: 100px;
      top: 45px;
      cursor: pointer;
    }
  }
}
