.SocialResponsPC{
  margin-top: 130px;
  width: 100%;
  .parentBox{
    margin:0 auto;
    width: 1199px;
    margin-top: 198px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    .one{
      margin-bottom: 50px;
    }
  }
}