.aboutPC {
  .h2 {
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .aboutTitle {
      margin-right: 33px;
    }

    .aboutbq {
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }
  }

  .border {
    height: 2px;
    background-color: #fff;
  }

  .cont {
    display: flex;
    padding: 167px 0;
    height: 600px;
    box-sizing: content-box;
  }

  // contLeft
  .contLeft {
    width: 50vw;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 6px;
  }

  .contLeftMedia {
    width: 480px;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }

  .contLeftVideo {
    width: 480px;
    height: 100%;
    object-fit: cover;
  }

  .contLeftPosition {
    position: absolute;
    left: 260px;
    top: 0;
    font-size: 120px;
    line-height: 140px;
  }

  .en {
    font-size: 80px;
  }

  // contRight

  .contRight {
    width: 50vw;
    position: relative;
    padding-left: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contRightTop {
    font-size: 60px;
    line-height: 84px;
    margin-top: -10px;
    color: #F38400;
    font-style: italic;
  }

  .contRightTop_eg {
    margin-top: -10px;
    font-size: 44px;
    // width: 696px;
    font-family: 'HanSerifSC';
    line-height: 65px;
    font-weight: 400;
    margin-bottom: 36px;
    color: #F38400;
    font-style: italic;

  }

  .contRightCenter {
    font-size: 20px;
    padding-right: 10px;
  }

  .contRightBtm {
    display: flex;
    padding-bottom: 6px;
  }

  .contRightBtmLeft {
    width: 188px;
    height: 56px;
    line-height: 56px;
    border-radius: 28px;
    text-align: center;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    cursor: pointer;
  }

  .contRightBtmRight {
    width: 188px;
    height: 56px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #000;
    border: 1px solid #fff;
    font-size: 18px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .play {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.outModal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .ant-modal {
    width: 1500px !important;
    padding: 0;
    display: block;
    // top: 50%;
    // transform: translateY(-50%);
  }

  .ant-modal-close {
    color: #fff;
  }

  .openVideo {
    width: 1430px;
    // height: 300px;
    object-fit: contain;
    font-family: 'object-fit: contain';
  }
}