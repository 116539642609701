.ServicePc {
  padding: 100px 0;

  .h2 {
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .brandTitle {
      margin-right: 33px;
    }

    .brand {
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }
  }

  .border {
    height: 2px;
    background-color: #fff;
  }

  .player {
    height: calc(100vh - 122px);
    margin-top: 78px;
    position: relative;
    display: flex;
    width: 1064px;
    height: 852px;
    margin: 0 auto;

    .ball {
      height: 100%;
      object-fit: contain;
      display: block;
    }

    .card {
      position: absolute;
      transition: opacity 1s;
      display: flex;

      &:hover {
        opacity: 1;
        transition: opacity 1s;
        // &>.inside{
        //   opacity: 1;
        // }
      }

      .inside {
        background-color: #000000;
        z-index: 5;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 370px;
        height: 330px;
        border: 1px solid #F38400;

        .contain {
          position: absolute;
          left: 18px;
          padding-top: 9px;
          border-left: 1px solid #F38400;
          padding-left: 18px;
          width: 430px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
        }
      }

      .pos {
        z-index: 100;
        opacity: 0;
        position: absolute;
        border: 1px solid #fff;
        width: 250px;
        height: 200px;

        &:hover+.inside {
          opacity: 1;
        }
      }

      .pos0 {
        top: -45px;
        left: 180px;
      }

      .pos1 {
        top: -55px;
        left: 10px;
      }

      .pos2 {
        top: -50px;
        left: -100px;
      }

      .pos3 {
        top: -70px;
        left: -120px;
      }

      .pos4 {
        left: -30px;
        top: -100px;
      }

      .pos5 {
        left: 180px;
        top: -35px;
      }
    }

    .card0 {
      top: 130px;
      left: -100px;
    }

    .card1 {
      top: 100px;
      left: 400px;
    }

    .card2 {
      top: 200px;
      right: 200px;
    }

    .card3 {
      top: 550px;
      right: 300px;
    }

    .card4 {
      top: 550px;
      right: 700px;
    }

    .card5 {
      top: 650px;
      left: -100px;
    }
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-top: 9px;
    border: none;

    .knowMore {
      cursor: pointer;
      display: inline-block;
      width: 188px;
      height: 55px;
      text-align: center;
      line-height: 55px;
      background: #F38400;
      border-radius: 28px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}