.life{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  :global{
    .content{
      width: 341px;
      height: 341px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle :local{
        width: 325px;
        height: 325px;
        border: 3px solid #313131;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        animation: spin 2s linear infinite;
        transform-origin: 162px 162px;
        @keyframes spin {
          to {
            transform: rotate(1turn);
          }
        }
      }
      .circle1{
        border: 2px solid #313131;
        transform-origin: 162px 162px;
        // animation-delay: .5s;
      }
      .circle2{
        border: 4px solid #313131;
        transform-origin: 169px 169px;
        animation-delay: 1s;
      }
      .circle3{
        border: 6px solid #313131;
        transform-origin: 172px 172px;
        // animation-delay: 1.5s;
      }
      .circle4{
        border: 3px solid #313131;
        transform-origin: 165px 165px;
        animation-delay: 1.8s;
      }
      .text{
        display: flex;
        flex-direction: column;
        text-align: center;
        .text1{
          font-size: 13px;
          color: #F38400;
          line-height: 19px;
          font-family: 'PingFang';
          font-weight: bold;
        }
        .text2{
          font-size: 30px;
          line-height: 30px;
          margin-top: 29px;
          font-family: 'PingFang';
          font-weight: 500;
        }
        .text3{
          margin-top: 5px;
          font-size: 21px;
          line-height: 29px;
          font-family: 'PingFang';
          font-weight: 500;
        }
        .text4{
          margin: 0 auto;
          font-family: 'PingFang';
          font-weight: 500;
          width: 167px;
          font-size: 12px;
          line-height: 19px;
          margin-top: 26px;
        }
      }
    }
  }
}
