.NewsPc {
  padding: 100px 0;

  .h2 {
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .newbq {
      text-indent: 33px;
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }
  }

  .border {
    height: 2px;
    background-color: #fff;
  }

  .content {
    padding: 40px 70px 0 130px;
    display: flex;
    align-items: flex-end;

    .opa {
      opacity: 1;
    }

    .opaHalf {
      opacity: .5;
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .words {
        margin-top: 15px;
        margin-right: 50px;
        font-size: 14px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;

        p {
          max-width: 295px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .time {
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 15px;
      }
    }

    .blockOne {
      cursor: pointer;

      .packImg {
        // width: 558px;
        // height: 333px;
        // position: relative;
        margin-right: 10px;

        .photoOne {
          width: 558px;
          height: 333px;
          // position: absolute;
          // clip:rect(0px 558px 333px 0px);
          object-fit: scale-down;
          font-family: 'object-fit: scale-down';
        }
      }
    }
  }

  .look {
    width: 324.8px;
    display: flex;
    height: 35px;
    margin: 137px auto;
    border-bottom: 1px solid #fff;
    justify-content: space-between;
    padding-bottom: 18px;
    cursor: pointer;

    .btn {
      height: 16px;
      margin-bottom: 9px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}