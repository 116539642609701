.HonoraryQualiMob {
  :global {
    .honorBox {
      // height: 480px;
      border-bottom: 1px solid #fff;
      width: 100%;
      overflow: hidden;
      display: flex;

      .animateBox {
        display: flex;
        transition: all .3s;

        .outside {
          &:first-child {
            .timeBlock {
              border: none;
            }
          }

          .timeBlock {
            height: 800px;
            overflow: hidden;
            transition: all .3s;
            // display: flex;
            border-left: 1px solid #fff;
            position: relative;

            .blockOne {
              font-size: 17px;
              font-family: 'HanSerifSC';
              color: #FFFFFF;
              line-height: 20px;
              position: relative;
              position: absolute;
              transition: all .5s;
              top: 0;
              transform-origin: center center;
              transform: rotate(90deg) translateX(50%);
              left: 0;
            }

            .openblockOne {
              top: 30px;
              transform: rotate(0) translateX(0);
              transform-origin: center center;
              left: 24px;
              font-size: 67px;
            }

            .open {
              opacity: 0;
              will-change: opacity;
              overflow: hidden;
            }

            .showOpen {
              overflow-y: scroll;
              transition: all .5s;
              width: 308px;
              height: calc(100% - 100px);
              padding-left: 25px;
              position: relative;

              .detaile {
                // position: absolute;
                // top: 129px;
                // margin-top: 100px;
                font-size: 12px;
                font-family: 'PingFang';
                font-weight: 400;
                color: #959595;
                line-height: 21px;
              }

              .imgItem {
                width: 237px;
                height: 185px;
                object-fit: cover;
                // position: absolute;
                // bottom: 29px;
                margin-top: 10px;
                margin-bottom: 10px;
              }
            }
          }

        }
      }
    }
  }
}