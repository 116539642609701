.ContentBlockMob{
  .border{
    height: 1px;
    width: 100%;
    background-color: #FFFFFF;
  }
  .content{
    padding: 50px 9px 225px 10px;
    position: relative;
    .topTitle{
      display: flex;
      flex-direction: column;
      width: 100%;
      .topPart{
        .contentWords{
          margin-top: 19px;
          font-size: 12px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
          overflow: hidden;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          will-change: height;
        }
      }
      .contactUs{
        margin-top: 40px;
        width: 120px;
        height: 35px;
        background: #F38400;
        border-radius: 18px;
        text-align: center;
        line-height: 35px;
        font-size: 13px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        z-index: 10;
      }
    }
    .graph{
      position: absolute;
      width: 232px;
      right: 130px;
      // bottom: 0;
      z-index: 9;
    }
  }
}