// 在线字体包
// @import url('https://fonts.font.im/css?family=Hanalei+Fill');
// @import url('https://fonts.font.im/css?family=Roboto');

// @font-face {
//   font-family: "FZCQJW";
//   src:  url("../fonts/FZCQJW.woff") format("woff"), /* chrome, firefox */
//   url("../fonts/FZCQJW.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
//   url("../fonts/FZCQJW.svg#FZCuQian-M17S") format("svg"); /* iOS 4.1- */
//   font-style: normal;
//   font-weight: normal;
// }


@font-face {
  font-family: 'HanSansHWK';
  src: url("../font/SourceHanSansSC-Regular.otf") format("woff");
  // src: url("../font/SourceHanSansHWK-Regular.otf") format("woff");
  /* iOS 4.1- */
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "HanSerifSC";
  src: url("../font/SourceHanSerifSC-Regular.otf") format("woff");
  /* iOS 4.1- */
  font-style: normal;
  font-weight: normal;
}