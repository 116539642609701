.PresentPC {
  position: relative;

  .imgBack {
    width: 100%;
    height: 575px;
    object-fit: cover;
  }

  .block {
    height: 65px;
    display: flex;
    background-color: #000;

    .num {
      height: 38px;
      font-size: 70px;
      font-family: 'LucidaCalligraphy';
      font-weight: normal;
      color: #F38400;
      line-height: 40px;
      margin-right: 21px;
      font-style: oblique;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      will-change: transform opacity;
    }

    .unit {
      height: 40px;
      font-size: 38px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      margin-right: 23px;
    }

    .introduce {
      height: 26px;
      font-size: 28px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 50px;
    }

    .describe {
      font-size: 26px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 40px;
    }

    @keyframes wordOpen {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.4);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  .block0 {
    top: 116px;
    position: absolute;
    left: 505px;
  }

  .block1 {
    top: 214px;
    position: absolute;
    right: 225px;
  }

  .block2 {
    position: absolute;
    top: 316px;
    left: 215px;
  }

  .block3 {
    top: 420px;
    position: absolute;
    left: 835px;
  }
}