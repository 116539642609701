.GoodnessPC {
  .title {
    padding: 63px 100px;
    padding-top: 0;
  }

  .borders {
    height: 2px;
    background: #FFFFFF;
    width: 100%;
  }

  .ant-collapse-item {
    border-bottom: 2px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    // 动画持续时间
    animation-duration: .6s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelTop {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .panel_header {
    padding: 42px 95px 42px 100px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    // 动画持续时间
    animation-duration: .6s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;

    .panel_title {
      display: flex;
      align-items: center;
    }

    .num {
      font-size: 36px;
      vertical-align: middle;

      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      margin-right: 38px;
    }

    .panel_zh {
      vertical-align: middle;
      font-size: 36px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      user-select: none;
    }

    .panel_en {
      vertical-align: middle;
      font-size: 26px;
      width: 750px;
      font-family: 'Alibaba PuHuiTi';
      font-weight: 400;
      color: #999999;
      display: inline-block;
      text-align: left;
      user-select: none;
    }
  }

  .panel_cont {
    padding: 66px 186px 65px 148px;
    width: 100%;
    border-top: 2px solid rgba($color: #fff, $alpha: .4);
    overflow: hidden;
    white-space: nowrap;

    .cont_img {
      width: 785px;
      height: 731px;
      object-fit: cover;
      margin-right: 138px;
      display: inline-block;
      vertical-align: middle;
      font-family: 'object-fit: cover';
    }

    .cont {
      width: 572px;
      word-break: break-all;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      font-size: 22px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #959595;
      line-height: 43px;
    }
  }
}