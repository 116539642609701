.ContactForm {
  margin: 35px 15px 0;
  border-bottom: 1px solid #fff;
  padding-bottom: 22px;

  :global {
    .header {
      display: flex;
      flex-direction: column;

      .left {
        display: flex;

        .zh {
          font-size: 30px;
          line-height: 20px;
          transition: all .3s;
          font-weight: 500;
          transform: translateY(60px);
          opacity: 0;
        }

        .cn {
          font-size: 15px;
          line-height: 28px;
          color: #F38400;
          text-indent: 20px;
          font-family: 'HanSerifSC';
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }

        .en {
          font-size: 15px;
          line-height: 28px;
          color: #F38400;
          font-family: 'HanSerifSC';
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
      }

      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        .tips {
          font-size: 16px;
          font-family: 'PingFang';
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          position: relative;
          top: 13px;
          left: 0;
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
      }
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 62px;

  :global {
    .btns {
      margin: 0 auto;
      width: auto;
      display: flex;
      justify-content: center;
    }

    .ant-form-item-explain {
      font-size: 10px;
    }

    .submit {
      background-color: #F38400;
      width: 120px;
      height: 35px;
      text-align: center;
      border-radius: 18px;
      border: none;
      margin-top: 30px;
      font-size: 13px;
      font-family: PingFang;
      font-weight: bold;
      color: #FFFFFF;
    }

    .onreset {
      background-color: transparent;
      width: 120px;
      height: 35px;
      border: 1px solid #FFFFFF;
      border-radius: 18px;
      text-align: center;
      margin-left: 11px;
      margin-top: 30px;
      font-size: 13px;
      font-family: PingFang;
      font-weight: bold;
      color: #FFFFFF;
    }

    .bq_col {
      transition: all .3s;
      transform: translateY(60px);
      opacity: 0;
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }

  }
}