.SocialResponsMob{
  margin-top: 50px;
  width: 100%;
  :global{
    .parentBox{
      margin:0 auto;
      width: 290px;
      margin-top: 55px;
      font-size: 12px;
      font-family: PingFang;
      font-weight: 400;
      color: #FFFFFF;
      .one{
        width: 250px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
}