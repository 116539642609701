.RotationPC{
  position: relative;
  margin-bottom: 150px;
  .circle{
    position: absolute;
    bottom: -100px;
  }
  .ant-carousel .slick-dots li{
    height:10px;
    width:10px;
    border:1px solid #fff;
    border-radius:50%;
  }
  .ant-carousel .slick-dots li button{
    height:10px;
    width:10px;
    border:1px solid #F38400;
    border-radius:50%;
  }
  .ant-carousel>.slick-dots.dotsss>li>button{
    height:10px;
    width:10px;
    border:1px solid #F38400;
    border-radius:50%;
  }
  .ant-carousel .slick-dots li.slick-active{
    height:10px;
    width:10px;
    border:1px solid #F38400;
    border-radius:50%;
    background-color: #F38400;
  }
  .ant-carousel .slick-dots li.slick-active button{
    background-color: #F38400;
  }
  .blockItem{
    display: flex !important;
    justify-content: center;
    .item1{
      width: 585px;
      height: 485px;
      margin-right: 137px;
      margin-top: 186px;
      .imgScial{
        width: 100%;
        height: 412px;
        object-fit: cover;
      }
      .describe{
        margin-top: 13px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .date{
        margin-top: 12px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
      }
      &:last-child{
        margin-right: 0;
        margin-top: 298px;
      }
    }
  }
  .leftButton{
    position: absolute;
    left: 305px;
    bottom: -100px;
    cursor: pointer;
  }
  .rightButton{
    position: absolute;
    right: 305px;
    bottom: -100px;
    cursor: pointer;
  }
}