.ContactUs {
  padding: 80px 100px 16px;

  :global {

    .zh,
    .cn,
    .li {
      transition: all .3s;
      transform: translateY(60px);
      opacity: 0;
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }

    .header {
      margin-bottom: 30px;
      display: flex;

      .zh {
        font-size: 60px;
        line-height: 80px;
        margin-right: 35px;
      }

      .cn {
        font-size: 30px;
        line-height: 80px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }

      .en {
        font-size: 50px;
        line-height: 80px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }
    }

    .ul {
      .li {
        padding: 50px 0 60px;
        font-family: 'HanSerifSC';
        display: flex;
        border-bottom: 1px solid #FFF;

        &:last-child {
          border: none;
        }

        .label {
          flex-grow: 1;
          color: #666666;
          font-size: 34px;
          line-height: 54px;
        }

        .value {
          width: 1060px;
          display: flex;
          flex-wrap: wrap;
        }

        .phone {
          font-size: 42px;
          line-height: 54px;
        }

        .email {
          display: flex;
          flex-direction: column;
          width: 447px;

          &:nth-child(3) {
            margin-top: 40px;
          }

          &:nth-child(4) {
            margin-top: 40px;
          }

          .email_label {
            font-size: 18px;
            line-height: 24px;
            margin-top: 7px;
            color: #999;
          }

          .email_value {
            font-size: 36px;
            line-height: 1;
          }
        }

        .address {
          font-size: 30px;
          line-height: 54px;
        }

        .share {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .share_icon {
            display: flex;

            li {
              margin-right: 27px;

              a {
                width: 30px;
                height: 26px;
                display: inline-block;

                .icon_img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }

          .img {
            width: 100px;
            height: 100px;
            background-color: #fff;
          }
        }
      }
    }
  }
}