.life{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  :global{
    .content{
      width: 754px;
      height: 754px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle :local{
        width: 740px;
        height: 740px;
        border: 10px solid #313131;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        animation: spin 2s linear infinite;
        transform-origin: 350px 350px;
        @keyframes spin {
          to {
            transform: rotate(1turn);
          }
        }
      }
      .circle1{
        border: 2px solid #313131;
        transform-origin: 365px 365px;
        animation-delay: .5s;
      }
      .circle2{
        border: 4px solid #313131;
        transform-origin: 360px 360px;
        animation-delay: 1s;
      }
      .circle3{
        border: 6px solid #313131;
        transform-origin: 375px 375px;
        animation-delay: 1.5s;
      }
      .circle4{
        border: 10px solid #313131;
        transform-origin: 380px 380px;
        animation-delay: 1.8s;
      }
      .text{
        display: flex;
        flex-direction: column;
        text-align: center;
        .text1{
          font-size: 26px;
          color: #F38400;
          line-height: 46px;
        }
        .text2{
          font-size: 60px;
          line-height: 80px;
          margin-top: 52px;
        }
        .text3{
          font-size: 42px;
          line-height: 62px;
        }
        .text4{
          font-size: 18px;
          line-height: 38px;
          margin-top: 38px;
        }
      }
    }
  }
}
