// 动画-关键帧
// 渐入渐出
@keyframes fadein_key {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes fadeout_key {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 800px;
    opacity: 0;
  }
}
// 宽度-打开闭合
@keyframes open_key {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes close_key {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
//高度打开
@keyframes open_height{
  0%{
    height: 0;
  }
  100%{
    height: 100%;
  }
}
@keyframes open_height_width{
  0%{
    height: 0;
    width: 0;
  }
  
  100%{
    height: 100%;
    width: 100%;
  }
}
@keyframes close_height_width{
  0%{
    height: 0;
    width: 0;
  }
  100%{
    height: 100%;
    width: 100%;
  }
}
