.CaseLightPC {
  .h2 {
    padding: 0 100px;
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .caseTitle {
      margin-right: 33px;
    }

    .ch {
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }
  }

  .border {
    height: 2px;
    background-color: #fff;
  }
}