.bq_input{
  position: relative;
  height: 60px;
  display: flex;
  flex-direction: column;
  width: 244px;
  :global{
    .label{
      font-size: 14px;
      line-height: 22px;
      color: #999;
    }
    // 重置输入框样式
    .ant-input{
      // width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #535353;
      background-color: transparent;
      outline: none;
      caret-color: #fff;
      box-sizing: border-box;
      height: 34px;
      line-height: 34px;
      font-size: 16px;
      color: #fff;
      box-shadow: none;
      &:hover{
        outline: none;
        box-shadow: none;
      }
    }
  }
}