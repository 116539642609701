.twoImage {
  width: 100%;
  display: flex;

  :global {
    .imageItem {
      width: 100%;

      &:first-child {
        padding-left: 97px;
        margin-top: 110px
      }

      &:last-child {
        padding-left: 43px;
        margin-top: 110px
      }
    }

    .img {
      width: 100%;
      object-fit: contain;
    }
  }
}