.bq_input {
  width: 100%;
  position: relative;
  height: 50px;
  margin-top: 50px;
  display: flex;
  align-items: flex-end;

  :global {
    .bq_label {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      transition: all .3s ease-out;
      z-index: 10;
      cursor: text;

      .zh {
        font-size: 22px;
        line-height: 40px;
        transition: all .3s ease-out;
        margin-right: 20px;
      }

      .cn {
        font-size: 20px;
        line-height: 40px;
        font-family: 'HanSerifSC';
        transition: all .3s .1s ease-out;
      }
    }

    .bq_label_active {
      transform: translateY(-30px);

      .zh_active {
        font-size: 14px;
      }

      .cn_active {
        font-size: 12px;
      }
    }

    // 重置输入框样式
    .ant-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #535353;
      margin-right: 20px;
      background-color: transparent;
      outline: none;
      caret-color: #fff;
      padding: 0 5px;
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      color: #fff;
      box-shadow: none;
      padding-bottom: 10px;

      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

  }
}