.DevelopHistoryMob {
  margin-bottom: 70px;

  :global {
    .developBox {
      padding: 57px 0 0 8px;

      .topItem {
        display: flex;
        justify-content: space-between;

        .left {
          width: 51px;
          border-left: 1px dotted #525252;

          .time {
            .timeItem {
              display: flex;
              height: 32px;

              .rod {
                width: 10px;
                height: 1px;
                background: #525252;
                margin: auto;
                margin-right: 10px;
              }

              .timeDetaile {
                width: 35px;
                height: 30px;
                font-size: 15px;
                font-family: 'PingFang';
                font-weight: bold;
                color: #FFFFFF;
                line-height: 32px;
                cursor: pointer;
              }
            }
          }
        }

        .right {
          height: 350px;
          width: 282px;
          margin-right: 10px;

          .photo {
            height: 350px;
            width: 282px;
            object-fit: cover;
          }
        }
      }
    }

    .bigTime {
      margin: 0 auto;
      margin-top: 30px;
      width: 300px;
      height: 78px;
      font-size: 64px;
      font-family: "HanSerifSC";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
      border-bottom: 1px solid #FFFFFF;
    }

    .word {
      margin: 0 auto;
      margin-top: 36px;
      width: 300px;
      font-size: 10px;
      font-family: " PingFang";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 19px;
    }
  }
}