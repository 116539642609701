.SmallBannerPC {
  height: 80px;

  .contentTitle {
    display: flex;
    align-items: flex-end;

    .title_ch {
      height: 56px;
      font-size: 60px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #FFFFFF;
      margin-right: 39px;
    }

    .title_eg {
      height: 32px;
      font-size: 30px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      line-height: 56px;
    }

    .title_en {
      height: 56px;
      font-size: 60px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #F38400;
      margin-right: 39px;
    }
  }
}