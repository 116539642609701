.fourEcharts_mob {
  padding: 0px 10px 16px 10px;
  position: relative;

  ul.list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      position: relative;
      width: 169px;
      height: 169px;
      animation-duration: 0s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      opacity: 1;
      margin-top: 16px;
    }
  }

  .big_circle {
    width: 169px;
    height: 169px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: content-box;
    transform: rotate(-22deg);

    .wrapper {
      width: 84px;
      height: 171px;
      position: absolute;
      top: 0;
      overflow: hidden;
      box-sizing: content-box;
    }

    // .right{
    //   right:1;
    // }
    // .left{
    //   left:1px;
    // }
    .circleProgress {
      width: 167px;
      height: 167px;
      border: 2px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      box-sizing: content-box;
      will-change: transform opacity;
      opacity: 0;
    }

    .rightcircle {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      right: 0;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      // animation: circleProgressLoad_right 1s linear infinite;
    }

    .leftcircle {
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      left: 0;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }

    @keyframes circleProgressLoad_right {
      0% {
        transform: rotate(225deg);
        opacity: 1;
      }

      50% {
        transform: rotate(225deg);
        opacity: 1;
      }

      100% {
        transform: rotate(45deg);
        opacity: 1;
      }
    }

    @keyframes circleProgressLoad_left {
      0% {
        transform: rotate(225deg);
        opacity: 1;
      }

      50% {
        transform: rotate(45deg);
        opacity: 1;
      }

      100% {
        transform: rotate(45deg);
        opacity: 1;
      }
    }
  }

  // 小圆
  .small_circle {
    width: 149px;
    height: 149px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    box-sizing: content-box;
    background-color: transparent;
    transform: rotate(22deg);

    .small_circle_success {
      width: 139px;
      height: 139px;
      border: 5px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      box-sizing: content-box;
      z-index: -10;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      opacity: 0;
      will-change: transform opacity;
    }

    .wrapper {
      width: 74px;
      height: 149px;
      position: absolute;
      top: 0;
      overflow: hidden;
      box-sizing: content-box;
    }

    // .right{
    //   right:0px;
    // }
    // .left{
    //   left:0px;
    // }
    .circleProgress {
      width: 139px;
      height: 139px;
      border: 5px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      box-sizing: content-box;
      will-change: transform;
      opacity: 0;
      z-index: 1;
    }

    .rightcircle {
      border-top: 5px solid #fff;
      border-right: 5px solid #fff;
      border-left: 5px solid #000;
      border-bottom: 5px solid #000;
      right: 0;
      animation-duration: 1s;
      animation-timing-function: linear;
      // animation-delay: .2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      // animation: circleProgressLoad_right 1s linear .2s infinite;
    }

    .leftcircle {
      border-top: 5px solid #000;
      border-right: 5px solid #000;
      border-bottom: 5px solid #fff;
      border-left: 5px solid #fff;
      left: 0;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      // animation: circleProgressLoad_left 1s linear .2s infinite;
    }

    @keyframes circleProgressLoad_right {
      0% {
        transform: rotate(225deg);
        opacity: 1;
      }

      50% {
        transform: rotate(225deg);
        opacity: 1;
      }

      100% {
        transform: rotate(45deg);
        opacity: 1;
      }
    }

    @keyframes circleProgressLoad_left {
      0% {
        transform: rotate(225deg);
        opacity: 1;
      }

      50% {
        transform: rotate(45deg);
        opacity: 1;
      }

      100% {
        transform: rotate(45deg);
        opacity: 1;
      }
    }

    @keyframes circleProgressLoad_success {
      0% {
        z-index: 0;
        opacity: 0;
      }

      99% {
        z-index: 0;
        opacity: 0;
      }

      100% {
        z-index: 10;
        opacity: 1;
      }
    }
  }

  .circle_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 169px;
    height: 169px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 3;
    will-change: transform;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;

    @keyframes open_cont {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .cont {
      width: 125px;
      height: 83px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .cont_top {
        font-size: 32px;
        // line-height: 30px;
        display: flex;
        align-items: flex-end;

        .num {
          font-size: 35px;
          line-height: 40px;
          font-weight: normal;
          color: #F38400;
          font-family: 'HanSerifSC';
        }

        .title {
          line-height: 30px;
          transform: scaleY(7px);
          font-size: 16px;
          font-weight: 500;
        }
      }

      .cont_zh {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'HanSansHWK';
      }

      .cont_en {
        color: #F38400;
        font-size: 12px;
        font-family: 'HanSerifSC';
      }

      .cont_eg {
        width: 80px;
        text-align: center;
      }
    }
  }
}