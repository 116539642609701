.AdvantagePc_mob {
	padding-top: 50px;
	padding-bottom: 50px;

	.h2 {
		height: 85px;
		box-sizing: border-box;
		padding-top: 24px;
		line-height: 34px;
		padding-left: 15px;
		overflow: hidden;
		display: flex;
		border-bottom: 2px solid #fff;
		will-change: width opacity;
		white-space: nowrap;
		font-family: 'HanSansHWK';
	}

	.title_zh {
		font-size: 36px;
		user-select: none;
		margin-right: 12px;
	}

	.title_en {
		font-size: 30px;
		line-height: 15px;
		transform: translateY(13px);
		color: #f38400;
		font-family: 'HanSerifSC';
		user-select: none;
	}

	.ant-collapse-item {
		border-bottom: 2px solid #fff !important;
	}

	.panel_item {
		overflow: hidden;
		// 浏览器准备动画
		will-change: width;
		width: 0;
		// 动画持续时间
		animation-duration: 1s;
		// 动画速度曲线
		animation-timing-function: linear;
		// 动画次数
		animation-iteration-count: 1;
		// 动画结束后以保留最后一帧
		animation-fill-mode: forwards;
	}

	// 动画名称
	@keyframes panelWidth {
		0% {
			width: 0;
			opacity: 0;
		}

		100% {
			width: 100%;
			opacity: 1;
		}
	}

	.ant-collapse-header {
		padding: 0 !important;
	}

	.ant-collapse-content-box {
		padding-bottom: 0;
	}

	.panel_header {
		padding: 10px 15px 10px 15px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.leftFont {
			display: flex;
			flex-direction: column;

			.panel_zh {
				font-size: 25px;
				user-select: none;
				transition: opacity 0.3s;
				will-change: opacity;
				width: 310px;
				overflow: hidden;

				.panel_en_title {
					font-size: 20px;
				}
			}

			.panel_en {
				width: 310px;
				overflow: hidden;
				font-size: 12px;
				color: #f38400;
				user-select: none;
				font-family: 'HanSerifSC';
				transition: opacity 0.3s;
				will-change: opacity;
			}
		}

		.icon {
			width: 22px;
			height: 22px;
		}
	}

	.panel_cont {
		width: 100%;
		border-top: 2px solid rgba($color: #fff, $alpha: 0.4);
		padding: 22px 25px 39px 0;

		.cont_img {
			width: 325px;
			height: 286px;
			object-fit: cover;
			margin-right: 26px;
			display: inline-block;
			vertical-align: middle;
			font-family: 'object-fit: cover';
			margin-bottom: 28px;
		}

		.cont {
			width: 324px;
			// word-break: break-all;
			display: inline-block;
			vertical-align: middle;
			// white-space: normal;
		}
	}
}