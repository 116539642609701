.HonorarMob {
  padding: 15px;
  padding-top: 0;

  .allContent {
    width: 100%;
    display: flex;

    .column {
      width: 165px;
      margin-right: 15px;

      .outCart {
        width: 100%;
        height: 223px;
        margin-bottom: 15px;

        .cart {
          position: relative;
          z-index: 50;
          overflow: hidden;
          width: 0;
          height: 100%;
          will-change: width;
          // 动画持续时间
          animation-duration: 0.5s;
          // 动画速度曲线
          animation-timing-function: linear;
          // 动画次数
          animation-iteration-count: 1;
          // 动画结束后以保留最后一帧
          animation-fill-mode: forwards;
          transition: all;

          .time {
            position: absolute;
            top: 15px;
            left: 17px;
            font-size: 15px;
            font-family: 'HanSerifSC';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
          }

          .prize {
            width: 127px;
            position: absolute;
            left: 18px;
            bottom: 10px;
            font-size: 12px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #FFFFFF;
            line-height: 20px;
          }

          .icon {
            position: absolute;
            top: 0;
            right: 20px;
            width: 22px;
            height: 37px;
          }
        }

        .cartOpa {
          transition: all;
          will-change: width;
          // 动画持续时间
          animation-duration: 0.5s;
          // 动画速度曲线
          animation-timing-function: linear;
          // 动画次数
          animation-iteration-count: 1;
          // 动画结束后以保留最后一帧
          animation-fill-mode: forwards;
          position: absolute;
          top: 0px;
          right: 0px;
          height: 223px;
          width: 165px;
          background-color: #bf976d;
          z-index: 100;
        }

        @keyframes openCartMob {
          0% {
            width: 0;
            opacity: 0;
            border: 0;
          }

          100% {
            width: 165px;
            opacity: 1;
            border: 1px solid #999999;
          }
        }

        @keyframes closeCartMob {
          0% {
            height: 223px;
            width: 165px;
          }

          100% {
            width: 0;
          }
        }
      }
    }

    .column0 {
      margin-top: 30px;
    }

    .column1 {
      margin-top: 55px;
    }
  }
}