.why {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  :global {
    .title {
      margin-top: 60px;
      margin-left: 100px;
      display: flex;
      align-items: center;

      .zh {
        font-size: 60px;
        line-height: 100px;
        position: relative;
        top: 200px;
        opacity: 0;
        left: 0;
        transition: top .3s ease-in-out;
        margin-right: 37px;
      }

      .cn {
        font-size: 30px;
        color: #F38400;
        font-family: 'HanSerifSC';
        position: relative;
        top: 200px;
        opacity: 0;
        left: 0;
        transition: top .3s ease-in-out .1s;
      }

      .en_big {
        color: #F38400;
        font-size: 50px;
        font-family: 'HanSerifSC';
        font-weight: 400;
      }

      .active {
        top: 0;
        opacity: 1;
      }
    }

    .wrap {
      margin-top: 110px;
      position: relative;
      width: 100%;
      height: 668px;

      .left,
      .right {
        width: 580px;
        height: 580px;
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all .6s ease-in-out;
        opacity: 0;
      }

      .left {
        border: 5px solid #F38400;
        z-index: 2;

        .left_title {
          text-align: center;
          color: #F38400;
          font-size: 42px;
          line-height: 60px;
        }

        .left_content {
          font-size: 20px;
          line-height: 38px;
          text-align: center;
        }
      }

      .right {
        background: #1B1B1B;

        .right_title {
          text-align: center;
          color: #F38400;
          font-size: 42px;
          line-height: 60px;
        }

        .right_content {
          width: 84%;
          word-break: break-word;
          font-size: 20px;
          line-height: 38px;
          text-align: center;
        }
      }

      .active_left {
        transform: translateX(-547px);
        opacity: 1;
      }

      .active_right {
        transform: translateX(-32px);
        opacity: 1;
      }
    }
  }
}