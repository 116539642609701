.text {
  position: relative;
  width: 100%;

  :global {
    .htmlContent {
      overflow: hidden;
      text-align: center;

      .date {
        margin-top: 100px;
        font-size: 16px;
      }

      .title {
        margin-top: 60px;
        font-size: 54px;
        line-height: 66px;
      }

      .content {
        margin-top: 80px;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}