.OtherCasePC {
  padding: 115px 100px;
  display: flex;

  .items {
    margin-bottom: 128px;
    width: 537px;
    margin-right: 54px;

    // height: 790px;
    .topContent {
      border-bottom: 1px solid #F38400;

      .picture {
        width: 538px;
        height: 510px;
        position: relative;

        .pictureItem {
          z-index: 100;
          width: 538px;
          height: 510px;
          object-fit: cover;
        }

        .pictureBox {
          position: absolute;
          top: 0;
          width: 100%;
          height: 126px;
          background-color: #000;
          z-index: 500;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          will-change: opacity animation;
          opacity: 1;
        }

        @keyframes openPicture {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        @keyframes closePicture {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .brandName {
        margin-top: 50px;
        width: 142px;
        height: 51px;
      }

      .brandLogo {
        margin-top: 29px;
        font-size: 24px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 49px;
      }
    }

    .bottoms {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .labels {
        width: calc(100% - 80px);
        display: flex;
        flex-wrap: wrap;

        .labelItem {
          margin-right: 10px;
          margin-bottom: 10px;
          min-width: 140px;
          min-height: 38px;
          width: fit-content;
          padding: 0 5px;
          border: 1px solid #434343;
          border-radius: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .data {
        width: 100px;
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        height: 12px;
        line-height: 38px;
      }
    }
  }
}