.header {
	width: 100vw;
	height: 122px;
	z-index: 99;
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 100px;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;

	:global {
		.searchItem {
			border-bottom: 1px solid rgba($color: #ffffff, $alpha: .34);

			.searchInnerBtn {
				width: 20px;
				cursor: pointer;
				height: 20px;
			}

			.searchWord {
				height: 54px;
				width: 766px;
				background: transparent;
				border: 0 none;
				padding-bottom: 22px;
				// color: #FFFFFF; 
				resize: none;
				outline: none;
				/*清除选中效果的默认蓝色边框 */
				-webkit-appearance: none;
				/*清除浏览器默认的样式 */
				line-height: normal; //光标问题 

				&::-webkit-input-placeholder {
					/* WebKit browsers */
					color: #fff;
					font-size: 29px;
					font-family: 'HanSerifSC';
					font-weight: 400;
				}

				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #fff;
					font-size: 29px;
					font-family: 'HanSerifSC';
					font-weight: 400;
				}

				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #fff;
					font-size: 29px;
					font-family: 'HanSerifSC';
					font-weight: 400;
				}

				&::-ms-input-placeholder {
					/* Internet Explorer 10+ */
					color: #fff;
					font-size: 29px;
					font-family: 'HanSerifSC';
					font-weight: 400;
				}

				&:focus {
					color: #8b8791;
				}
			}
		}

		.logo {
			width: 175px;
			display: block;
		}

		.nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 900px;
		}

		.navItem {
			min-width: 51px;
			height: 36px;
			position: relative;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s;
			color: #fff;

			a {
				font-size: 20px;

			}

			&::after {
				content: '';
				width: 0;
				height: 2px;
				background-color: #F38400;
				position: absolute;
				bottom: 0;
				left: 50%;
				transition: all .3s;
			}

			&:hover {
				color: #F38400;

				&::after {
					left: 0;
					width: 100%;
				}
			}
		}

		.selectedNav {
			color: #F38400;

			&::after {
				width: 100%;
				left: 0;
			}
		}

		.rightMenu {
			display: flex;
			align-items: center;
			padding-right: 26px;
		}

		.searchBtn {
			margin-right: 59px;
			cursor: pointer;
			width: 16px;
			height: 16px;
		}

		.langeBtn {
			cursor: pointer;
		}
	}
}