.TitleHorMob {
	border-bottom: 1px solid #fff;
	margin-top: 50px;

	:global {
		.TitleHor {
			padding: 0 15px;
			padding-right: 0;
			display: flex;
			flex-direction: column;
			// height: 101px;
			box-sizing: border-box;
			margin-bottom: 20px;

			.chinese {
				font-size: 36px;
				font-family: 'PingFang';
				font-weight: 500;
				color: #ffffff;
				margin-right: 16px;
				// width: 155px;
				margin-bottom: 10px;
			}

			.english {
				font-size: 30px;
				font-family: 'HanSerifSC';
				font-weight: 400;
				color: #f38400;
				line-height: 28px;
			}
		}
	}
}