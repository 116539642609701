.TopBannerPC {
  padding: 80px 0 100px 0;

  .topInfor {
    padding-right: 100px;
    padding-left: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .slogan {
      font-size: 68px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 82px;
    }

    img {
      width: 110px;
      height: 99px;
      object-fit: contain;
    }
  }

  .bottomInfor {
    padding-right: 100px;
    padding-left: 100px;
    margin-bottom: 106px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    .label {
      display: flex;

      .labelItem {
        width: 140px;
        height: 38px;
        border: 1px solid #434343;
        border-radius: 19px;
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 10px;
        text-align: center;
        line-height: 38px;
      }
    }

    .dataInfom {
      display: flex;
      margin-right: 10px;
      font-size: 18px;
      font-family: 'PingFang';
      font-weight: 400;
      color: #FFFFFF;

      .num {
        margin-right: 68px;

        .icon {
          width: 19px;
          height: 12px;
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;

          .icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }

          .icon {
            width: unset;
          }
        }
      }
    }
  }

  .CaseBanner {
    margin-top: 106px;
    width: 100%;
    height: auto;

    // height: 970px;
    img {
      width: 100%;
      height: auto;
    }
  }
}