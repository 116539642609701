.BottomPicturePC{
  padding: 100px;
  .pictureInform{
    .bottomPhoto{
      width: 100%;
      height: 1220px;
      display: flex;
      .outsideLeft{
        width: 1084px;
        height: 1220px;
        margin-right: 95px;
        overflow: hidden;
        object-fit: cover;
        .rightPhoto{
          transition: all .3s ease-out;
          transform: scale(1);
          width: 0;
          height: 0;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          object-fit: cover;
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .outside{
        width: 481px;
        height: 635px;
        object-fit: cover;
        overflow: hidden;
        .leftPhoto{
          transition: all .3s ease-out;
          transform: scale(1);
          width: 0;
          height: 0;
          animation-duration: .5s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      
    }
    .bigBottom{
      margin-top: 141px;
      width: 1510px;
      height: 970px;
      object-fit: cover;
      overflow: hidden;
      .photoItem{
        transition: all .3s ease-out;
        transform: scale(1);
        width: 0;
        height: 0;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }
}