.section{
  height: 275px;
  display: flex;
  flex-direction: column;
  padding-left: 98px;
  padding-top: 30px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #fff;
  :global{
    .title{
      display: flex;
      align-items: center;
      .zh{
        font-size: 70px;
        line-height: 168px;
        margin-right: 40px;
      }
      .cn{
        font-size: 60px;
        line-height: 118px;
        margin-top: 12px;
        color: #F38400;
      }
    }
    .nav{
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 20px;
      line-height: 74px;
      .navItem{
        margin-right: 70px;
        color: #666666;
      }
      .active{
        color: #fff;
      }
    }
  }
}