.leaderTalkMob {
  :global {
    .content {
      display: flex;
      flex-direction: column;
      padding: 41px 15px 0;

      .leaderPic {
        img {
          width: 346px;
          height: 400px;
        }
      }

      .introduce {
        width: 100%;
        margin-top: 36px;
        background-image: url('../../../../assets/img/back_pc.png');
        background-size: 100% 100%;

        .position {
          font-size: 13px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #F38400;
          line-height: 30px;
          transition: all .3s;
          transform: translateY(100px);
          opacity: 0;
          margin-bottom: 24px;
          transition-delay: .2s;
        }

        .bqTarget {
          font-size: 13px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          transition: all .3s;
          transform: translateY(60px);
          transition-delay: .5s;
          opacity: 0;
        }
      }
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }
  }
}