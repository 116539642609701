.strategy {
  height: 8000px;
  position: relative;
  margin-top: 400px;

  :global {
    .lineList :local {
      position: absolute;
      top: 0;
      left: 390px;
      width: 1035px;
      height: 100%;
      // border: 1px solid red;
    }

    .contentList {
      position: absolute;
      top: -507.5px;
      left: 0;
      padding: 0 212px 0 110px;
      width: 100%;
      box-sizing: border-box;

      // overflow: auto;
      li {
        height: 1035px;
        width: 100%;
        transform: translateY(0);
        display: flex;
        align-items: center;

        // border: 1px solid #fff;
        // 形状在左边的样式
        &:nth-child(2n) {
          justify-content: flex-end;

          .contentText {
            align-items: flex-end;

            .text {
              text-align: right;
            }
          }

          .shapeBox {
            left: 0;
          }
        }

        // 形状在右边的样式
        &:nth-child(2n+1) {
          .contentText {
            margin-left: 100px;
          }

          .shapeBox {
            right: 0;
          }
        }

        .contentText {
          width: 760px;
          display: flex;
          flex-direction: column;
          position: relative;
          top: 400px;
          left: 0;
          opacity: 0;
          transition: top .3s ease-out, opacity .3s ease-out;

          .zh {
            font-size: 60px;
            line-height: 85px;
          }

          .cn {
            font-size: 60px;
            line-height: 90px;
            color: #F38400;
            font-family: 'HanSerifSC';
          }

          .text {
            font-size: 25px;
            line-height: 42px;
            margin-top: 40px;
            width: 100%;
            word-wrap: break-word;
          }

          .btns {
            display: flex;
            align-items: center;
            margin-top: 194px;

            .btn {
              width: 188px;
              height: 55px;
              line-height: 55px;
              font-size: 18px;
              border-radius: 27.5px;
              text-align: center;
              cursor: pointer;
              margin-right: 20px;
            }

            .zh {
              font-family: 'AlibabaPuHuiTiR';
            }

            .cn {
              font-family: 'HanSerifSC';
            }

            .default {
              background-color: #fff;
              color: #000;
              border: 1px solid #000;
            }

            .black {
              background-color: #000;
              color: #fff;
              border: 1px solid #fff;
            }
          }
        }

        .shapeBox {
          width: 560px;
          height: 560px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          // 查看中写点
          // &::before :local{
          //   content: '';
          //   position: absolute;
          //   width: 6px;
          //   height: 6px;
          //   background: red;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          //   z-index: 10000;
          // }
        }
      }
    }
  }
}

.line {
  height: 6px;
  background-color: #fff;
  position: absolute;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-fill-mode: both;
  opacity: 1;

  @keyframes open_frames {
    0% {
      width: 0;
    }

    100% {
      width: 1464px;
    }
  }

  // 形状在左边
  &:nth-child(2n) {
    left: 0;
    transform-origin: left top;
    transform: translateY(6px) rotate(45deg);
  }

  // 形状在右边
  &:nth-child(2n+1) {
    background-color: #fff;
    right: 0;
    transform-origin: right top;
    transform: translateY(6px) rotate(-45deg);
  }

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 1035px;
  }

  &:nth-child(3) {
    top: 2070px;
  }

  &:nth-child(4) {
    top: 3105px;
  }

  &:nth-child(5) {
    top: 4140px;
  }

  &:nth-child(6) {
    top: 5175px;
  }

  &:nth-child(7) {
    top: 6210px;
  }

  &:nth-child(8) {
    top: 7245px;
  }

  &:nth-child(9) {
    top: 8280px;
  }

}