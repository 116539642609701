.LeaderPC {
  .block {
    width: 100%;
    height: 810px;
    display: flex;

    .left {
      width: 50%;
      height: 810px;
      border-right: 2px solid #F9F9F9;
      border-bottom: 2px solid #F9F9F9;

      .introduce {
        height: 245px;
        width: 100%;
        // border-bottom: 2px solid #F9F9F9;
        display: flex;
        align-items: center;
        justify-content: center;

        .one {
          // width: 485px;
          height: 59px;
          font-size: 63px;
          font-family: 'FZCuQian-M17S';
          font-weight: 400;
          color: #F38400;
          // margin-right: 29px;
        }

        .one_eg {
          font-size: 46px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #F38400;
          margin-right: 33px;
          font-style: italic;
        }

        .two {
          width: 105px;
          height: 35px;
          font-size: 52px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
        }

        .two_eg {
          font-size: 40px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .ant-collapse-borderless>.ant-collapse-item {
        border-top: 2px solid #F9F9F9;
        border-bottom: none;
      }

      .panel_box {
        height: 563.5px;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar-thumb {
          background-color: #F38400;
        }

        scrollbar-color: #F38400 #000;
        scrollbar-width: thin;

        .panel_header {
          display: flex;
          height: 68px;
          padding-left: 100px;
          line-height: 68px;

          .time {
            width: 144px;
            display: inline-block;
            vertical-align: middle;
            font-size: 36px;
            font-family: 'HanSansHWK';
            font-weight: 500;
            color: #FFFFFF;
            user-select: none;
          }

          .time {
            width: unset;
          }

          .word {
            display: inline-block;
            user-select: none;
            font-size: 20px;
            font-family: 'HanSansHWK';
            font-weight: 500;
            color: #999999;
            color: #FFFFFF;
            align-items: center;
          }
        }

        .panel_cont {
          width: 100%;
          padding: 0 66px 21px 100px;

          .cont_img {
            width: 795px;
            font-size: 20px;
            font-family: 'HanSansHWK';
            font-weight: 400;
            color: #959595;
            line-height: 38px;
          }
        }
      }

    }

    .right {
      width: 50%;
      border-bottom: 2px solid #F9F9F9;
      position: relative;

      .triangle {
        position: absolute;
        top: 404px;
        right: -148px;
        width: 1254px;
        height: 2px;
        transform: rotate(-40.2deg);
        background-color: #F9F9F9;
      }

      .leaderImg {
        position: absolute;
        background-color: #000;
        width: 635px;
        height: 747px;
        object-fit: cover;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}