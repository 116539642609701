.TopPhotoPC {
  width: 100%;
  height: fit-content;

  .topContent {
    width: 100%;
    height: fit-content;
    position: relative;

    .backPhoto {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .target {
      font-size: 120px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 140px;
      top: 326px;
      right: 180px;
      position: absolute;
    }

    .zh {
      font-family: 'HanSansHWK';
    }

    .cn {
      text-align: right;
      font-family: 'HanSerifSC';
    }
  }
}