.GoodnessMob {
  .title {
    padding: 15px;
    padding-top: 85px;
    padding-bottom: 0;
  }

  .borders {
    height: 1px;
    background: #FFFFFF;
    width: 100%;
  }

  .ant-collapse-item {
    border-bottom: 1px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .panel_header {
    padding: 20px;
    padding-left: 15px;
    overflow: hidden;
    display: flex;

    .num {
      font-size: 18px;
      vertical-align: middle;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      margin-right: 19px;
      width: 16px;
    }

    .contentWord {

      // height: 78px;
      // line-height: 70px;
      .panel_zh {
        // height: 13px;
        width: 305px;
        font-size: 18px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        user-select: none;
        margin-bottom: 10px;
      }

      .panel_en {
        // height: 13px;
        font-size: 13px;
        font-family: 'Alibaba PuHuiTi';
        font-weight: 400;
        color: #999999;
        display: inline-block;
        user-select: none;
      }
    }
  }

  .panel_cont {
    padding: 39px 12px 33px 0;
    width: 100%;
    border-top: 1px solid rgba($color: #fff, $alpha: .4);
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cont_img {
      width: 349px;
      height: 233px;
      object-fit: cover;
      display: inline-block;
      vertical-align: middle;
      font-family: 'object-fit: cover';
    }

    .cont {
      margin-top: 44px;
      width: 285px;
      word-break: break-all;
      vertical-align: middle;
      white-space: normal;
      font-size: 13px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #999999;
      line-height: 23px;
    }
  }
}