.IntroducePC{
  padding: 200px 100px 0 100px;
  .pictureInform{
    box-sizing: content-box;
    height: 170px;
    .introduce{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 1426px;
      .leftInform{
        font-size: 77px;
        font-family: PingFang;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 92px;
      }
      .rightInform{
        font-size: 30px;
        font-family: PingFang;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 55px;
        height: 84px;
      }
    }
  }
}