.closePage {
  text-align: center;
  width: 238px !important;
  padding-bottom: 28px;

  :global {
    .ant-modal-header {
      height: 34px;
      padding: 0;

      .ant-modal-title {
        line-height: 34px;
        margin-right: 33px;
        text-align: right;
      }
    }

    .ant-modal-title {
      font-size: 7px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #CCCCCC;
      line-height: 16px;
    }

    .ant-modal-close-x {
      width: 10px;
      height: 10px;
      font-size: 15px;
      line-height: 34px;
      margin-right: 10px;
    }

    .ant-modal-body {
      padding: 0 15px;
      height: 254px;

      .img {
        margin-top: 29px;
        width: 37px;
        height: 42px;
        margin-bottom: 29px;
      }

      .word {
        font-size: 18px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
        margin-bottom: 12px;
      }

      .contant {
        font-size: 8px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
        margin-bottom: 32px;
      }

      .button {
        width: 94px;
        height: 28px;
        background: #F38400;
        border-radius: 14px;
        margin: 0 auto;
        line-height: 28px;
        font-size: 9px;
        font-family: 'Alibaba PuHuiTi';
        font-weight: 400;
        color: #FFFFFF;
      }
    }

  }
}