.OurHonorPc_mob {
  padding: 0 15px 50px;
  display: flex;

  // flex-wrap: wrap;
  :global {
    .content {
      width: 50%;
      margin-right: 12px;
      transition-property: all;

      .outBlock {
        height: 244px;
        margin-bottom: 10px;
      }
    }

    .content1 {
      margin-right: 0;
      transition: all 10ms;
      will-change: transform;
    }
  }
}

.outCart {
  position: relative;
  width: 167px;
  height: 244px;
  margin-bottom: 10px;

  :global {
    .cart {
      width: 166px;
      height: 244px;
      background-color: black;
      position: absolute;
      border-radius: 2px;
      backface-visibility: hidden;

      .honorImg {
        position: absolute;
        right: 13px;
        width: 22px;
        height: 37px;
      }

      .name {
        // width: 60px;
        height: 27px;
        position: absolute;
        top: 165px;
        left: 5px;
        // right: 269px;
        font-size: 30px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 18px;
      }

      .english {
        position: absolute;
        top: 212px;
        left: 5px;
        width: 100%;
        height: 16px;
        font-size: 15px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
      }

      .egWord {
        height: 24px;
        font-size: 23px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 23px;
        position: absolute;
        top: 190px;
        left: 16px;
      }
    }
  }
}

.honer {
  // margin-right: 12px;
  width: 167px;
  height: 167px;
  border-radius: 50%;
  margin-top: 48px;
  background-color: #F38400;
  margin-bottom: 48px;
  position: relative;

  :global {
    .all {
      width: 130px;
      height: 49px;
      font-size: 24px;
      font-family: 'PingFang';
      font-weight: bold;
      color: #FFFFFF;
      line-height: 18px;
      // transform: rotate(20deg);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      text-align: center;
    }

    .honorEg {
      margin-top: 25px;
      width: 90px;
      height: 28px;
      font-size: 18px;
      font-family: 'RTWS ShangYa Demo';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
      // transform: rotate(20deg);
      position: absolute;
      top: 70px;
      bottom: 0;
      right: 0px;
      left: 0;
      margin: auto;
    }

    .egWord {
      height: 55px;
      font-size: 30px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 18px;
      position: absolute;
      transform: rotate(20deg);
      top: 20px;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      text-align: center;
    }
  }
}

.backHeight {
  width: 169px;
  height: 246px;

  :global {
    .backCard {
      position: absolute;
      width: 169px;
      height: 246px;
      background: #F38400;
      border-radius: 2px;
      padding: 20px 15px 14px 11px;
      margin-bottom: 31px;
      backface-visibility: hidden;

      .contain {
        width: 142px;
        height: 200px;
        font-size: 11px;
        // font-family: 'HanSansHWK';
        font-weight: 400;
        color: #FFFFFF;
        overflow-y: scroll;
        line-height: 20px;
      }

      .more {
        width: 12px;
        height: 6px;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 14px;
        transform: translateX(-50%);
      }
    }
  }
}