.ContactUs {
  padding: 40px 15px 16px;

  :global {

    .zh,
    .cn,
    .li {
      transition: all .3s;
      transform: translateY(60px);
      opacity: 0;
    }

    .open_active {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }

    .header {
      margin-bottom: 13px;
      display: flex;

      .zh {
        font-size: 30px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'PingFang';
      }

      .cn {
        font-size: 15px;
        line-height: 28px;
        text-indent: 19px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }

      .en {
        font-size: 30px;
        line-height: 20px;
        color: #F38400;
        font-family: 'HanSerifSC';
      }
    }

    .ul {
      .li {
        padding: 30px 0 30px;
        font-family: 'HanSerifSC';
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #FFF;

        &:last-child {
          border: none;
        }

        .label {
          flex-grow: 1;
          font-size: 17px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #666666;
          line-height: 15px;
          margin-bottom: 11px;
        }

        .value {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }

        .phone {
          font-size: 21px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 19px;
        }

        .email {
          display: flex;
          flex-direction: column;
          width: 50%;

          &:nth-child(3) {
            margin-top: 18px;
          }

          &:nth-child(4) {
            margin-top: 18px;
          }

          .email_label {
            font-size: 12px;
            line-height: 21px;
            margin-top: 9px;
            color: #999;
            font-family: 'HanSerifSC-Regular';
          }

          .email_value {
            font-size: 18px;
            font-family: 'HanSerifSC-Regular';
            line-height: 21px;
          }
        }

        .address {
          font-size: 16px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 19px;
        }

        .bottomItem {
          display: flex;

          .left {
            display: flex;
            flex-direction: column;

            .share {
              font-size: 17px;
              font-family: 'HanSerifSC';
              font-weight: 400;
              color: #666666;
              line-height: 15px;
              width: 150px;
            }

            .share_icon {
              display: flex;
              margin-top: 22px;

              img {
                margin-right: 13px;
                width: 15px;
                height: 15px;
              }
            }
          }

          .img {
            width: 79px;
            height: 79px;
            background-color: #fff;
          }
        }

      }
    }
  }
}