.OverseasUnderPC {
  .moduleBlock {
    margin-top: 227px;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    height: 594px;
    position: relative;

    .paris {
      position: absolute;
      top: 120px;
      left: 725px;
    }

    .newYork {
      position: absolute;
      bottom: 61px;
      left: 140px;
    }

    .name {
      position: absolute;
      width: 535px;
      font-size: 34px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 48px;
      text-align: center;
      top: 200px;
      left: 262px;
    }

    .img {
      width: 602px;
      height: 689px;
      position: absolute;
      right: 172px;
      top: -60px;
      transform: rotate(15deg);
    }

    .eng {
      position: absolute;
      right: 80px;
      top: 120px;
      width: 528px;
      height: 185px;
      font-size: 246px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      font-style: italic;
      color: #F38400;
      line-height: 125px;
    }
  }
}