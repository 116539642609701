.caseListMob {
  .caseBox {
    padding-top: 51px;
    margin-bottom: 19px;

    .h2 {
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 36px;
      font-family: 'PingFang';
      font-weight: 500;
      overflow: hidden;
      display: flex;
      margin-bottom: 38px;

      .casebq {
        text-indent: 20px;
        transform: translateY(10px);
        font-size: 30px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
      }
    }

    .block {
      margin-top: 10px;
      border-bottom: 1px solid #fff;

      .industry {
        padding: 0 15px;
        height: 30px;
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: 400;
        margin-bottom: 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          display: inline-block;
          width: 10px;
          height: 5px;
        }
      }

      .allIndustry {
        display: flex;
        padding: 0 15px;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .indusName {
          // width: 67px;
          height: 20px;
          font-size: 12px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 10px;
          margin-bottom: 10px;
          margin-right: 15px;
        }

        .selectIndus {
          // width: 67px;
          height: 20px;
          background: #F38400;
          border-radius: 10px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .eng {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .chs {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
    }

    .sort {
      padding: 0 15px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      height: 57px;

      .sortName {
        width: 30px;
        height: 12px;
        font-size: 12px;
        font-family: 'HanSansHWK';
        font-weight: 400;
        color: #666666;
        // line-height: 33px;
      }

      .sortNameEg {
        width: 59px;
        height: 15px;
        font-size: 15px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #666666;
        line-height: 33px;
        margin-bottom: 20px;
      }

      .selectNum {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        height: 12px;

        .select {
          font-size: 12px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #999999;
          // width: 57px;
          padding: 0 10px;
          height: 12px;
          cursor: pointer;

          &:first-child {
            padding-left: 0;
          }
        }

        .selectSort {
          color: #FFFFFF;
        }
      }
    }

  }

  .brand {
    padding: 0 15px;
    margin-bottom: 55px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .cardBox {
      width: 345px;
      height: 371px;
      margin-bottom: 55px;

      .brandTop {
        border-bottom: 1px solid #F38400;
        height: 323px;
        width: 100%;

        .brandPhoto {
          width: 345px;
          height: 223px;
          object-fit: cover;
        }

        .brandName {
          margin-top: 30px;
          min-width: 70px;
          height: 20px;
          object-fit: cover;
        }

        .brandDetaile {
          margin-top: 11px;
          // height: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .cardBottom {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;

        .labels {
          display: flex;

          .labelItem {
            // width: 90px;
            height: 24px;
            // padding: 0 10px 2px 10px;
            border: 1px solid #434343;
            border-radius: 12px;
            font-size: 13px;
            font-family: 'HanSansHWK';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            margin-right: 10px;
          }
        }

        .data {
          height: 12px;
          font-size: 12px;
          font-family: 'HanSansHWK';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 27px;
        }
      }
    }

    .more {
      width: 120px;
      height: 35px;
      background: #F38400;
      border-radius: 18px;
      cursor: pointer;
      font-size: 13px;
      line-height: 35px;
      text-align: center;
    }
  }
}