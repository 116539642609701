.newBrandsPc {
  padding: 100px 0;

  .h2 {
    height: 204px;
    box-sizing: border-box;
    padding-top: 64px;
    line-height: 140px;
    font-size: 68px;
    padding-left: 95px;
    overflow: hidden;
    display: flex;

    .brandTitle {
      margin-right: 33px;
    }

    .brand {
      font-size: 58px;
      line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
      font-family: 'HanSerifSC';
    }

    .hint {
      font-size: 16px;
      color: #fff;
      opacity: 1 !important;
      margin-left: 32px;
      transform: translateY(25px);
    }
  }

  ul.picture {
    margin-top: 0;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 100px;
    box-sizing: border-box;

    li {
      // height: 100%;
      // width: 200px;
      position: relative;
      cursor: pointer;

      .photos {
        display: inline-block;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        word-break: break-word;

        &:hover {
          opacity: 1;
          font-size: 20px;
          font-weight: 600;
          // color: #F38400;
        }
      }

      .unactive {
        opacity: .5;


      }

      .active {
        opacity: 0.6;
        animation-name: breath;
        animation-duration: 2500ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        &:hover {
          animation-play-state: paused;
          // animation-name: none;
        }
      }

      @keyframes breath {
        from {
          opacity: 0.7;
          transform: scale(.75);
        }

        50% {
          opacity: 1;
          transform: scale(.95);
        }

        to {
          opacity: 0.7;
          transform: scale(.75);
        }
      }
    }
  }

  ul.titles {
    width: 100%;
    margin-top: 54px;
    padding-left: 95px;
    padding-right: 95px;

    .bottom {
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      .brand {
        width: 150px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 85px;
        font-size: 20px;
        font-family: 'AlibabaPuHuiTiR';
        font-weight: 400;

        .name {
          color: #999999;
          cursor: pointer;
          height: 20px;
          width: 90px;
          line-height: 20px;
          text-align: center;
        }

        .changeWhite {
          color: #fff;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}