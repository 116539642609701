.oneImage{
  width: 100%;
  box-sizing: border-box;
  :global{
    .img{
      width: 100%;
      object-fit: contain;
    }
  }
}
