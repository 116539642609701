.footer {
	width: 100vw;
	padding-bottom: 138px;
	position: relative;

	:global {
		.topNav {
			display: flex;
			padding: 0 89px 35px 94px;
		}

		.menu {
			display: flex;
			flex-direction: column;
			width: 400px;

			dt {
				height: 110px;
				font-size: 44px;
				line-height: 1;
				color: #F38400;
			}

			dd {
				font-size: 18px;
				line-height: 32px;
			}
		}

		.contact {
			display: flex;
			flex-direction: column;
			width: 450px;

			&.size-large {
				width: 600px;
			}

			dt {
				height: 110px;
				font-size: 44px;
				line-height: 1;
				color: #F38400;
			}

			dd {
				min-height: 90px;
				min-height: 40px;
			}
		}

		.label {
			color: #666666;
			font-size: 14px;
			line-height: 30px;
		}

		.value {
			font-size: 24px;
			line-height: 30px;
			white-space: pre-wrap;

			&+.value {
				margin-top: 10px;
			}
		}

		.lastShare {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
		}

		.share {
			display: flex;
			flex-direction: column;
			// align-items: center;
			min-width: 158px;

			dt {
				height: 110px;
				font-size: 44px;
				line-height: 1;
				color: #F38400;
			}

			dd {
				.top {
					width: 30px;
					height: 26px;
					margin-bottom: 79px;
					margin-right: 25px;
				}

				.bottom {
					width: 158px;
					height: 158px;
				}
			}
		}

		.centerForm {
			padding: 0 100px 100px 93px;

			.form-title {
				color: #999;
				font-size: 16px;
				line-height: 24px;
			}

			.form {
				margin-top: 65px;
				display: flex;
			}

			.formItem {
				width: 244px;
				margin-right: 20px;
			}

			.formItem_te {
				width: 430px;
				margin-right: 70px;

				.input_te {
					width: 430px !important;
				}
			}

			.submit {
				width: 154px;
				height: 42px;
				background-color: #fff;
				color: #000;
				border-radius: 24px;
				text-align: center;
				outline: none;
				border: none;
				cursor: pointer;
			}
		}

		.anbei {
			padding-left: 97px;
			display: flex;

			.security {
				color: #666666;
				margin-right: 7px;
				line-height: 32px;
			}

			a :hover {
				color: #F38400;
			}

		}
	}
}