:root {
  --background-image: url('../../../../assets/img/brand.png');
  --background-hover: url('../../../../assets/img/brand_yellow.png');
}

$Background: var(--background-image);
$BackgroundHover: var(--background-hover);

.BrandsMob {
  padding: 66px 0 51px 0;

  .h2 {
    position: relative;
    height: 82px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: 'HanSansHWK';
    font-weight: 500;
    padding-left: 14px;
    overflow: hidden;
    display: flex;
    margin-right: 17px;

    .brandTitle {
      margin-right: 17px;
    }

    .brand {
      font-size: 30px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      // line-height: 140px;
      transform: translateY(13px);
      color: #F38400;
    }

    .hint {
      position: absolute;
      font-size: 12px;
      top: 55px;
      left: 18px;
    }
  }

  .border {
    height: 1px;
    background-color: #fff;
  }

  ul.picture {
    margin-top: 70px;
    width: 100%;
    padding-left: 23px;
    padding-right: 23px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    li {
      height: 100px;
      width: 100px;

      .photos {
        height: 100%;
        width: 100%;
        position: relative;

        &:hover {
          transform: scale(1) !important;
        }

        .img {
          z-index: 50;
          // background: url(../../../../assets/img/brand.png) 0 0 no-repeat;
          background: $Background 0 0 no-repeat;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: scale(.5) translate(-85%, -85%);
          backface-visibility: hidden;

          &:hover {
            z-index: 10;
            // background: url(../../../../assets/img/brand_yellow.png) 0 0 no-repeat;
            background: $BackgroundHover 0 0 no-repeat;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: scale(.6) translate(-70%, -70%);
            backface-visibility: hidden;
            opacity: 1;
          }
        }
      }

      .unacitve {
        transform: scale(.75);
        opacity: 0.5;

        &:hover {
          opacity: 1;
          transform: scale(1);
        }
      }

      .active {
        opacity: 0.5;
        animation-name: breath;
        animation-duration: 2500ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        &:hover {
          animation-play-state: paused;
          opacity: 1;
          transform: scale(1) !important;
        }
      }

      .showNo {
        animation-play-state: paused;
        opacity: 0.4;
      }

      .showLight {
        opacity: 1;
        transform: scale(.80);
      }

      @keyframes breath {
        from {
          opacity: 0.6;
          transform: scale(.75);
        }

        50% {
          opacity: 1;
          transform: scale(.85);
        }

        to {
          opacity: 0.6;
          transform: scale(.75);
        }
      }
    }
  }

  ul.titles {
    width: 100%;
    margin-top: 54px;
    padding-left: 23px;
    padding-right: 23px;

    .bottom {
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #fff;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      box-sizing: border-box;

      .brand {
        width: 58px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 9px;
        font-size: 13px;
        font-family: AlibabaPuHuiTiR;
        font-weight: 400;
        margin-bottom: 33px;

        &:nth-child(5) {
          margin-right: 0;
        }

        .name {
          color: #999999;
          cursor: pointer;
          height: 25px;
          // width: 90px;
          line-height: 20px;
          text-align: center;
          padding-bottom: 9px;
        }

        .changeWhite {
          color: #fff;
          border-bottom: 1px solid #F38400;

        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}