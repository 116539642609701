.strategyMob {
  padding: 50px 13px;

  .bottomAni {
    .blockItem {
      height: 680px;
      position: relative;

      &:nth-child(2n) {
        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          box-sizing: border-box;
          width: 345px;
          position: absolute;
          transition: top .3s ease-out, opacity .3s ease-out;
          top: 200px;
          opacity: 0;
          text-align: right;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
        width: 345px;
        position: absolute;
        transition: top .3s ease-out, opacity .3s ease-out;
        top: 200px;
        opacity: 0;

        .head {
          height: 28px;
          font-size: 30px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 15px;
          margin-bottom: 9px;
        }

        .engName {
          height: 32px;
          font-size: 30px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #F38400;
          line-height: 28px;
        }

        .content {
          margin-top: 46px;
          font-size: 12px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }

        .btn {
          margin-top: 49px;
          height: 35px;
          display: flex;
          z-index: 10;

          a {
            font-size: 13px;
            font-weight: bold;
            color: #000000;
            line-height: 35px;
          }

          .more {
            width: 120px;
            height: 35px;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            border-radius: 18px;
            line-height: 35px;
            display: flex;
            justify-content: center;

          }

          .black {
            margin-left: 20px;
            width: 120px;
            height: 35px;
            border: 1px solid #FFFFFF;
            border-radius: 18px;
            font-size: 13px;
            color: #FFFFFF;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
          }
        }
      }

      .boxStart {
        top: 0;
        opacity: 1;
      }

      .picture {
        position: absolute;
        width: 100%;
        top: 320px;
        height: 232px;
      }
    }
  }
}