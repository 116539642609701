.reward {
  padding-bottom: 0px;

  :global {
    .header {
      height: 51px;
      display: flex;
      flex-direction: column;
      padding: 0 17px;

      .zh {
        font-size: 30px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 10px;
      }

      .cn {
        font-size: 15px;
        font-family: HanSerifSC;
        font-weight: 400;
        color: #F38400;
        line-height: 28px;
      }
    }

    .list {
      padding: 8px 16px 0;

      .li {
        border-bottom: 1px solid #fff;
        padding: 35px 0;
        display: flex;
        flex-direction: column;

        &:last-child {
          border: none;
        }

        .label {
          font-size: 21px;
          font-family: 'PingFang';
          font-weight: bold;
          color: #F38400;
          line-height: 19px;
          margin-bottom: 28px;
        }

        .list_p {
          font-size: 13px;
        }

        .html {
          width: 100%;
        }
      }
    }
  }
}

.awardTable {
  border-collapse: collapse;
  margin-top: 74px;
  font-size: 20px;
  border-color: #ffffff;
  width: 100%;
  border: 1px solid #ffffff;
  text-align: center;

  thead {
    th {
      height: 50px;
      border: 1px solid #ffffff;
    }
  }

  tbody {
    td {
      height: 62px;
      font-family: 'HanSerifSC';
      border-color: #ffffff;
      font-size: 14px;
      border: 1px solid #ffffff;

      &:last-child {
        color: #F38400;
      }
    }
  }
}