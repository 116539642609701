.TitleMob {
  border-bottom: 1px solid #fff;
  margin-top: 50px;

  .title {
    padding: 0 15px;
    padding-right: 0;
    display: flex;
    height: 50px;
    box-sizing: border-box;
    margin-bottom: 25px;

    .chinese {
      font-size: 36px;
      font-family: PingFang;
      font-weight: 500;
      color: #FFFFFF;
      margin-right: 16px;
      width: 155px;
    }

    .english {
      font-size: 30px;
      font-family: HanSerifSC;
      font-weight: 400;
      color: #F38400;
      line-height: 70px;
      // width: 200px;
    }
  }
}