.join {
  padding: 40px 0;

  :global {
    .header {
      height: 51px;
      display: flex;
      padding: 0 17px;

      .zh {
        font-size: 30px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 10px;
        margin-right: 15px;
      }

      .cn {
        font-size: 15px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 28px;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      li {
        padding-bottom: 35px;
        width: 50%;
        text-align: center;

        img {
          width: 120px;
          height: 120px;
        }

        a {
          display: block;
          cursor: pointer;

          &:hover {
            color: #F38400;
          }
        }

        .joinImg {
          width: 80px;
          height: 80px;
          margin: 20px;
        }

        .fw600 {
          font-weight: 600;
        }
      }


    }
  }
}