.footer_mob {
  width: 100vw;
  padding-bottom: 61px;
  position: relative;

  .topNav {
    padding: 0 15px 0 15px;

    .headContant {
      display: flex;
      border-bottom: 1px solid rgba($color: #ffff, $alpha: 0.2);
      width: 100%;

      .contact {
        display: flex;
        flex-direction: column;
        // width: 172px;
        margin-bottom: 38px;
        padding-right: 12px;

        dt {
          height: 30px;
          font-size: 30px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          line-height: 1;
          color: #F38400;
          margin-bottom: 33PX;
        }

        dd {
          min-height: 38px;
          margin-bottom: 5px;

          .label {
            color: #666666;
            font-size: 12px;
            line-height: 15px;
          }

          .value {
            font-size: 15px;
            line-height: 20px;
            white-space: pre-wrap;
          }
        }
      }
    }

    .code {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // justify-content: center;
      // margin: auto;
      text-align: center;
      margin-top: 25px;

      .BQcode {
        width: 109px;
        height: 109px;
        // margin-right: 53px;
      }

      .platform {
        width: 24px;
        height: 21px;
        margin-right: 20px;
        line-height: 21px;
      }
    }

    .anbei {
      margin-top: 41px;
      font-size: 12px;
      font-family: 'AlibabaPuHuiTiR';
      font-weight: 400;

      span {
        color: #666666;
        display: inline-block;
        margin-right: 20px;
      }

      a {
        text-decoration: underline;
      }

      a:link {
        color: #666666;
      }

      .copyright {
        // display: flex;
        margin-bottom: 10px;

        a {
          color: #666666;
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .statement {
        display: flex;

        a {
          color: #666666;
          margin-right: 1px;
        }
      }
    }
  }
}