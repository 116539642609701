.BusinessCasePC {
  margin-top: 100px;
  margin-bottom: 179px;

  .title {
    padding: 63px 100px;
    padding-top: 0;
  }

  .borders {
    height: 2px;
    background: #FFFFFF;
    width: 100%;
  }

  .ant-collapse-item {
    border-bottom: 2px solid #fff !important;
  }

  .panel_item {
    overflow: hidden;
    // 浏览器准备动画
    will-change: width;
    width: 0;
    // 动画持续时间
    animation-duration: 1s;
    // 动画速度曲线
    animation-timing-function: linear;
    // 动画次数
    animation-iteration-count: 1;
    // 动画结束后以保留最后一帧
    animation-fill-mode: forwards;
  }

  // 动画名称
  @keyframes panelWidth {
    0% {
      width: 0;
      opacity: 0;
    }

    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 95px 0 100px !important;
  }

  .panel_header {
    height: auto;
    min-height: 118px;
    padding: 0 95px 0 100px;
    line-height: 118px;
    // overflow: hidden;
    display: flex;
    align-items: center;

    // justify-content: center;
    .panel_zh {
      vertical-align: middle;
      flex-basis: 831px;
      font-size: 50px;
      user-select: none;
      font-family: 'HanSansHWK';
      font-weight: 500;
      transition: color .3s;
      will-change: color;
    }

    .panel_en {
      flex: 1;
      // width: 450px;
      // margin-right: 381px;
      line-height: 38px;
      font-size: 26px;
      font-family: 'HanSansHWK';
      font-weight: 500;
      color: #999999;
      user-select: none;
      transition: color .3s;
      will-change: color;
    }

    .icon {
      vertical-align: middle;
      width: 46px;
      height: 46px;
    }
  }

  .panel_cont {
    width: 100%;
    border-top: 2px solid rgba($color: #fff, $alpha: .4);
    padding: 100px 0;
    overflow: hidden;
    white-space: nowrap;

    .cont_img {
      width: 785px;
      height: 731px;
      object-fit: cover;
      margin-right: 72px;
      display: inline-block;
      vertical-align: middle;
      font-family: 'object-fit: cover';
    }

    .cont {
      width: 861px;
      word-break: break-all;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      font-size: 22px;
      font-family: 'HanSansHWK';
      font-weight: 400;
      color: #959595;
      line-height: 43px;
    }
  }
}