.newsList {
  display: flex;
  margin: 50px 42px;

  :global {
    .colList {
      margin: 0 29px;
      width: 33.333%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      transition: animation .1s;
    }
  }
}

.front {
  width: 100%;
  height: 100%;
  padding: 72px 50px 100px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global {
    .frontTop {
      display: flex;
      justify-content: space-between;

      .num {
        font-size: 22px;
        font-family: 'HanSerifSC';
      }

      .icon {
        width: 72px;
        height: 58px;
      }
    }

    .frontBtm {
      width: 100%;
      display: flex;
      flex-direction: column;

      .zh {
        font-size: 60px;
        line-height: 84px;
      }

      .cn {
        line-height: 60px;
        font-family: 'HanSerifSC';
      }

      .cn1 {
        font-size: 36px;
      }

      .zh1 {
        font-size: 50px;
      }
    }
  }
}

.back {
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 72px 50px 100px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #F38400;

  :global {
    .backTop {
      margin-bottom: 48px;

      .num {
        font-size: 22px;
        font-family: 'HanSerifSC';
      }
    }
  }
}