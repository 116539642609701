
.bq_card_open{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  :global{
    .open_body{
      width: 100%;
      overflow: hidden;
      padding: 0 18px;
      box-sizing: border-box;
    }
    .open_transition{
      width: 0;
      overflow: hidden;
      box-sizing: border-box;
      animation-duration: .3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      will-change: width;
      position: relative;
    }
  }
}