.closePage{
  text-align: center;
  width: 476px !important;
  :global{
    .ant-modal-header{
      height: 68px;
      padding: 0;
      .ant-modal-title{
        line-height: 68px;
        margin-right: 66px;
        text-align: right;
      }
    }
    .ant-modal-title{
      font-size: 14px;
      font-family: 'PingFang';
      font-weight: 500;
      color: #CCCCCC;
    }
    .ant-modal-close-x{
      width: 20px;
      height: 20px;
      line-height: 68px;
      margin-right: 20px;
    }
    .ant-modal-body{
      padding: 0;
      height: 447px;
      .img{
        margin-top: 57px;
        width: 74px;
        height: 84px;
        margin-bottom: 57px;
      }
      .word{
        font-size: 36px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
        margin-bottom: 24px;
        height: 34px;
      }
      .contant{
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
        margin-bottom: 63px;
      }
      .button{
        width: 188px;
        height: 55px;
        background: #F38400;
        border-radius: 28px;
        margin: 0 auto;
        line-height: 55px;
        font-size: 18px;
        font-family: 'Alibaba PuHuiTi';
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
    
  }
}