.club{
  width: 100%;
  // position: relative;
  :global{
    .title :local{
      font-size: 42px;
      color: #F38400;
      line-height: 62px;
      margin: 70px 0 0 98px;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      
      @keyframes title_open {
        0% {
          transform: translateY(30px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes title_close {
        0% {
          transform: translateY(0);
          opacity: 1;
        }
        100% {
          transform: translateY(30px);
          opacity: 0;
        }
      }
    }
  }
}
