.text{
  position: relative;
  width: 100%;
  margin-bottom: 59px;
  :global{
    .htmlContent{
      overflow: hidden;
      padding: 0 24px 0 22px;
    }
  }
}
