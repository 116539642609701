.BqTargetMob {
  padding: 58px 15px;

  .introduce {
    display: flex;
    flex-direction: column;

    // height: 540px;
    .eg_word {
      font-size: 29px;
      font-family: 'HanSerifSC';
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 33px;
    }

    .position {
      margin-bottom: 46px;

      .lineOne {
        display: flex;
        margin-bottom: 7px;

        .whiteWord {
          height: 25px;
          font-size: 26px;
          font-weight: 400;
          color: #FFFFFF;
          margin-right: 9px;
        }

        .yellowWord {
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          height: 29px;
          font-size: 31px;
          font-weight: normal;
          color: #F38400;
          margin-right: 7px;
          font-style: oblique;
          will-change: transform opacity;
        }
      }
    }

    @keyframes open {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }

    .detaileIntro {
      display: flex;
      flex-direction: column;

      .introOne {
        width: 100%;
        font-size: 14px;
        font-family: PingFang;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 23px;
        margin-bottom: 37px;
        overflow: hidden;
      }

      .introTwo,
      .introThree {
        width: 100%;
        font-size: 12px;
        font-family: PingFang;
        font-weight: 400;
        color: #FFFFFF;
        overflow: hidden;
        line-height: 18px;
        // height: 60px;
      }

      .introTwo {
        height: 120px;
        margin-bottom: 30px;
      }
    }
  }
}