.PagesPC {
  /* 火狐 */
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;

  .bottomPage {
    width: 100%;
    height: 108px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    padding: 45px 99px 29px 95px;
    margin-bottom: 170px;

    .ant-pagination {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      color: #fff;
      font-size: 20px;
      font-family: 'HanSerifSC';
      font-weight: 400;

      .ant-pagination-item-link {
        border: none;
      }

      .ant-pagination-item {
        border: none;
        width: 40px;
        height: 40px;
        padding-bottom: 15px;

      }

      .ant-pagination-item-active {
        border-bottom: 2px solid #F38400;

      }

      .ant-pagination-item-ellipsis {
        color: #fff;
        font-size: 20px;
        font-family: 'HanSerifSC';
        font-weight: 400;
      }

      .ant-pagination-prev {
        position: absolute;
        left: 0;
      }

      .anticon-arrow-left {
        position: absolute;
        left: 0;
        // top: -30%;
      }

      .anticon-arrow-right {
        position: absolute;
        right: 0;
        // top: -30%;
      }

      .ant-pagination-next {
        position: absolute;
        right: 0;
      }

      .anticon {
        vertical-align: 4px;
      }
    }

  }
}