.ServiceAdvantagesMob{
  padding: 30px 15px;
  padding-bottom: 0;
  .contents{
    padding: 100px 15px 0px 15px;
    position: relative;
    height: 332px;
    box-sizing: border-box;
    box-sizing: content-box;
    .advantage{
      position: absolute;
      left: calc(50% - 84px);
      top: calc(50% - 84px);
      transition: all 1s;
      display: flex;
      flex-direction: column;
      align-items: center;
      .big{
        width: 161px;
        height: 161px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1s;
        .small{
          width: 134px;
          height: 134px;
          border: 6px solid #F38400;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .word{
            font-size: 13px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }
      .describe{
        text-align: center;
        margin-top: 24px;
        font-size: 13px;
      }
    }
    .big0{
      opacity: 0;
    }
    .big1{
      opacity: 0;
    }
    .big2{
      opacity: 0;
    }
  }
  .one{
    height: 150px !important;
    margin-bottom: -50px;
    .big{
      opacity: 1;
    }
  }
  .even{
    height: 250px !important;
    margin-bottom: 50px;
    .advantage{
      opacity: 1;
      &:nth-child(1) {
        transform: translate(-90px,-60px);

        .big{
          opacity: 1;
        }
      }
      &:nth-child(2) {
        transform: translate(90px,-60px);
        .big{
          opacity: 1;
        }
      }
      &:nth-child(3) {
        transform: translateY(100px);
        .big{
          opacity: 1;
        }
      }
    }
  }
  .odd{
    height: 150px !important;
    margin-bottom: -50px;
    .advantage{
      opacity: 1;
      &:nth-child(1) {
        transform: translateX(-90px);
        .big{
          opacity: 1;
        }
      }
      &:nth-child(2) {
        transform: translateX(90px);
        .big{
          opacity: 1;
        }
      }
    }
  }
}