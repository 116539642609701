.news {
  :global {
    .h2 {
      padding-top: 20px;
      font-size: 30px;
      padding-left: 13px;
      overflow: hidden;
      font-family: PingFang;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      flex-direction: column;

      .newTitle {
        height: 28px;
      }

      .newbq {
        margin-top: 10px;
        font-size: 30px;
        line-height: 28px;
        color: #F38400;
        font-weight: 400;
        font-family: 'HanSerifSC';
        margin-bottom: 35px;
      }
    }

    .border {
      height: 1px;
      background-color: #fff;
    }

    .content {
      padding: 41px 16px 0 15px;

      .words {
        margin-top: 11px;
        font-size: 12px;
        font-family: PingFang;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.5;
        width: 236px;
        height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .date {
        margin-top: 4px;
        width: 67px;
        height: 15px;
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.5;
        margin-bottom: 34px;
      }

      .blockOne {
        .photoOne {
          width: 345px;
          height: 206px;
          object-fit: cover;
          font-family: 'object-fit: cover';
        }
      }

      .blockTwo {
        .pictureTwo {
          width: 346px;
          height: 294px;
          object-fit: cover;
          font-family: 'object-fit: cover';
        }
      }
    }

    .look {
      width: 120px;
      display: flex;
      height: 35px;
      margin: 56px auto;
      justify-content: center;
      cursor: pointer;
      background: #F38400;
      border-radius: 18px;
      font-size: 13px;
      font-family: PingFang;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 35px;

      .btn {
        height: 13px;
        margin-bottom: 8px;
      }
    }
  }
}