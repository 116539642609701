.JobList{
  padding: 0 15px;
  :global{
    .li{
      display: flex;
      padding: 38px 0 25px;
      flex-direction: column;
      border-bottom: 1px solid #fff;
      &:last-child{
        border: none;
      }
      .name{
        font-size: 21px;
        font-family: 'PingFang';
        font-weight: bold;
        color: #F38400;
        line-height: 19px;
        margin-bottom: 33px;
      }
      .detail{
        width: 100%;
        .post{
          .label{
            font-size: 13px;
            font-family: 'PingFang';
            font-weight: bold;
            color: #FFFFFF;
            line-height: 28px;
          }
          .dl{
            margin-top: 18px;
            font-size: 12px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #FFFFFF;
            line-height: 16px;
          }
        }
        .nead{
          .order{
            display: flex;
            .jiantou{
              margin-top: 44px;
              margin-left: 10px;
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
          }
          .label{
            font-size: 12px;
            font-family: 'PingFang';
            font-weight: bold;
            color: #FFFFFF;
            line-height: 28px;
            display: flex;
            align-items: center;
            margin-top: 40px;
            cursor: pointer;
          }
          .dl_box2{
            height: 0;
            overflow: hidden;
            font-size: 12px;
            transition: all 1s ;
            opacity: 0;
          }  
          .dl2{
            height: auto;
            transition: all 1s ;
            overflow: visible;
            margin-top: 18px;
            font-size: 12px;
            font-family: 'PingFang';
            font-weight: 500;
            line-height: 16px;
            opacity: 1;
          }
        }
      }
    }

  }
}
