.bq_input {
  width: 100%;
  position: relative;
  height: 37px;
  // margin-top: 25px;
  display: flex;
  align-items: flex-end;

  :global {
    .bq_label {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      transition: all .3s ease-out;
      z-index: 10;
      cursor: text;

      .zh {
        font-size: 16px;
        font-family: PingFang;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        transition: all .3s ease-out;
      }

      .cn {
        font-size: 16px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        text-indent: 8px;
        transition: all .3s .1s ease-out;
      }
    }

    .bq_label_active {
      transform: translateY(-15px);

      .zh_active {
        font-size: 12px;
      }

      .cn_active {
        font-size: 12px;
      }
    }

    // 重置输入框样式
    .ant-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #535353;
      background-color: transparent;
      outline: none;
      caret-color: #fff;
      padding: 5px;
      box-sizing: border-box;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      color: #fff;
      box-shadow: none;

      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

  }
}