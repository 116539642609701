.OtherCaseMob {
  padding: 45px 15px 55px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .items {
    margin-bottom: 40px;
    width: 345px;
    height: 372px;

    .topContent {
      height: 325px;
      border-bottom: 1px solid #F38400;

      .pictureItem {
        width: 345px;
        height: 223px;
        object-fit: cover;
      }

      .brandName {
        margin-top: 30px;
        width: 70px;
        height: 20px;
      }

      .brandLogo {
        margin-top: 11px;
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .bottoms {
      margin-top: 23px;
      display: flex;
      justify-content: space-between;

      .labels {
        display: flex;
        flex-wrap: wrap;

        .labelItem {
          margin-right: 10px;
          margin-bottom: 10px;
          // width: 85px;
          height: 24px;
          border: 1px solid #434343;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: 'PingFang';
          font-weight: 400;
        }
      }

      .data {
        font-size: 12px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #FFFFFF;
        height: 12px;
        line-height: 24px;
      }
    }
  }

  .more {
    margin-top: 30px;
    width: 120px;
    height: 35px;
    background: #F38400;
    border-radius: 18px;
    text-align: center;
    line-height: 35px;
    font-size: 13px;
    font-family: 'PingFang';
    font-weight: bold;
    color: #FFFFFF;
  }
}