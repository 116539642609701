.TopBannerMob {
  padding: 51px 0 26px 15px;

  :global {
    .titleBox {
      display: flex;
      align-items: flex-end;

      .tltle_ch {
        font-size: 36px;
        font-family: 'PingFang';
        font-weight: 500;
        color: #FFFFFF;
        margin-right: 19px;
        // line-height: 70px;
      }

      .tltle_eg {
        font-size: 30px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
        line-height: 40px;

        a {
          font-size: 30px;
          font-family: 'HanSerifSC';
          font-weight: 400;
          color: #F38400;
          line-height: 40px;
        }
      }
    }
  }
}