.ContactForm{
  margin: 80px 100px 0;
  border-bottom: 1px solid #fff;
  padding-bottom: 133px;
  :global{
    .header{
      display: flex;
      justify-content: space-between;
      .left{
        display: flex;
        .zh{
          font-size: 60px;
          line-height: 80px;
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
        .cn{
          font-size: 30px;
          line-height: 80px;
          color: #F38400;
          text-indent: 38px;
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
        .en{
          font-size: 50px;
          line-height: 80px;
          color: #F38400;
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
      }
      .right{
        width: 1060px;
        display: flex;
        flex-direction: column;
        .tips{
          font-size: 32px;
          line-height: 52px;
          margin-top: 27px;
          position: relative;
          top: 13px;
          left: 0;
          transition: all .3s;
          transform: translateY(60px);
          opacity: 0;
        }
      }
    }
    .open_active{
      transform: translateY(0)!important;
      opacity: 1!important;
    }
  }
}
.form{
  display: flex;
  flex-direction: column;
  margin-top: 76px;
  :global {
    
    .submit{
      background-color: #F38400;
      width: 188px;
      height: 56px;
      text-align: center;
      border-radius: 28px;
      border: none;
      margin-top: 50px;
      color: #fff;
      font-size: 18px;
    }
    .onreset{
      background-color: transparent;
      width: 188px;
      height: 56px;
      text-align: center;
      border-radius: 28px;
      border: 1px solid #fff;
      margin-left: 20px;
      margin-top: 50px;
      font-size: 18px;
    }

    .bq_col{
      transition: all .3s;
      transform: translateY(60px);
      opacity: 0;
    }
    .open_active{
      transform: translateY(0)!important;
      opacity: 1!important;
    }
    
  }
}