.w100{
  width: 100%;
}
// margin
.mt2{
  margin-top: 2px;
}
.mr2{
  margin-right: 2px;
}
.ml2{
  margin-left: 2px;
}
.mb2{
  margin-bottom: 2px;
}
.mt5{
  margin-top: 5px;
}
.mr5{
  margin-right: 5px;
}
.ml5{
  margin-left: 5px;
}
.mb5{
  margin-bottom: 5px;
}
.mt10{
  margin-top: 10px;
}
.mr10{
  margin-right: 10px;
}
.ml10{
  margin-left: 10px;
}
.mb10{
  margin-bottom: 10px;
}

.mb15{
  margin-bottom: 15px;
}
.mt15{
  margin-top: 15px;
}
.mr15{
  margin-right: 15px;
}
.ml15{
  margin-left: 15px;
}

.mt20{
  margin-top: 20px;
}
.mr20{
  margin-right: 20px;
}
.ml20{
  margin-left: 20px;
}
.mb20{
  margin-bottom: 20px;
}
.mt30{
  margin-top: 30px;
}
.mr30{
  margin-right: 30px;
}
.ml30{
  margin-left: 30px;
}
.mb30{
  margin-bottom: 30px;
}
.mt40{
  margin-top: 40px;
}
.mr40{
  margin-right: 40px;
}
.ml40{
  margin-left: 40px;
}
.mb40{
  margin-bottom: 40px;
}
.mt60{
  margin-top: 60px;
}
.mr60{
  margin-right: 60px;
}
.ml60{
  margin-left: 60px;
}
.mb60{
  margin-bottom: 60px;
}

// padding
.pdt10{
  padding-top: 10px;
}
.pdl10{
  padding-left: 10px;
}
.pdr10{
  padding-right: 10px;
}
.pdb10{
  padding-bottom: 10px;
}
.pdt20{
  padding-top: 20px;
}
.pdl20{
  padding-left: 20px;
}
.pdr20{
  padding-right: 20px;
}
.pdb20{
  padding-bottom: 20px;
}

// 行高
.lh{
  line-height: 17px;
}

.lh32{
  line-height: 32px;
}

// 字号
.f12{
  font-size: 12px;
}
.f13{
  font-size: 13px;
}
.f14{
  font-size: 14px;
}
.f16{
  font-size: 16px;
}
.f18{
  font-size: 18px;
}
.f20{
  font-size: 20px;
}
.f22{
  font-size: 22px;
}
.f24{
  font-size: 24px;
}
.f26{
  font-size: 26px;
}
.f28{
  font-size: 28px;
}
.f30{
  font-size: 28px;
}
.f32{
  font-size: 28px;
}
.f34{
  font-size: 28px;
}
.f36{
  font-size: 28px;
}
.f38{
  font-size: 28px;
}
.f40{
  font-size: 28px;
}
.f42{
  font-size: 28px;
}
.f44{
  font-size: 28px;
}
.f46{
  font-size: 28px;
}
.f48{
  font-size: 28px;
}
.f50{
  font-size: 28px;
}
.f52{
  font-size: 28px;
}
.f54{
  font-size: 28px;
}
.f56{
  font-size: 28px;
}
.f58{
  font-size: 28px;
}
.f60{
  font-size: 28px;
}
.f62{
  font-size: 28px;
}
.f64{
  font-size: 28px;
}
.f66{
  font-size: 28px;
}
.f68{
  font-size: 28px;
}
.f70{
  font-size: 28px;
}
.f672{
  font-size: 28px;
}
.f74{
  font-size: 28px;
}
.f76{
  font-size: 28px;
}
.f78{
  font-size: 28px;
}
.f80{
  font-size: 28px;
}

