.ContactHead {
  display: flex;
  align-items: center;
  height: 226px;
  border-bottom: 2px solid #fff;
  padding: 0 100px;

  :global {
    .zh {
      font-size: 72px;
      line-height: 92px;
      margin-right: 38px;
    }

    .cn {
      font-size: 60px;
      line-height: 80px;
      color: #F38400;
      font-family: 'HanSerifSC';
      transform: translateY(12px);
    }
  }
}