.caseListPC {
  .caseBox {
    padding: 0 100px;
    padding-top: 80px;
    margin-bottom: 40px;

    .h2 {
      box-sizing: border-box;
      font-size: 73px;
      font-family: 'PingFang';
      font-weight: 500;
      display: flex;
      margin-bottom: 73px;

      .caseTitle {
        margin-right: 34px;
      }

      .casebq {
        line-height: 75px;
        transform: translateY(13px);
        font-size: 60px;
        font-family: 'HanSerifSC';
        font-weight: 400;
        color: #F38400;
      }
    }

    .industry {
      height: 30px;
      font-size: 20px;
      font-family: 'PingFang';
      font-weight: 400;
      margin-bottom: 21px;
    }

    .allIndustry {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .Industry {
        padding: 0 28px;
        // height: 42px;
        border-radius: 21px;
        color: #FFFFFF;
        // line-height: 42px;
        text-align: center;
        cursor: pointer;
      }

      .indusName {
        width: 155px;
        height: 42px;
        font-size: 18px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selectIndus {
        background: #F38400;
      }
    }

    .selectNum {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      // width: 385px;
      .selectChoose {
        font-size: 16px;
        font-family: 'HanSansHWK';
        font-weight: 400;
        color: #666666;
        // width: 70px;
        height: 16px;
        margin-right: 47px;
      }

      .select {
        font-size: 16px;
        font-family: 'PingFang';
        font-weight: 400;
        color: #999999;
        // width: 70px;
        height: 16px;
        cursor: pointer;
        margin-right: 47px;
      }

      .selectSort {
        color: #FFFFFF;
      }
    }
  }

  .border {
    height: 2px;
    background-color: #fff;
  }

  .brand {
    padding: 0 97px;
    margin-bottom: 40px;
    margin-top: 107px;
    display: flex;
    flex-wrap: wrap;

    .cardBox {
      width: 537px;
      height: 635px;
      margin-right: 44px;
      margin-bottom: 67px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .brandTop {
        border-bottom: 1px solid #F38400;
        height: 542px;
        width: 100%;

        .detaile {
          cursor: pointer;

          .brandPhoto {
            width: 538px;
            height: 348px;
            object-fit: cover;
          }

          .brandName {
            margin-top: 50px;
            // min-width: 162px;
            height: 40px;
            object-fit: cover;
          }

          .brandDetaile {
            margin-top: 29px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // height: auto;
            font-size: 24px;
            font-family: 'PingFang';
            font-weight: 400;
            color: #FFFFFF;
          }
        }

      }

      .cardBottom {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .labels {
          display: flex;

          .labelItem {
            min-width: 120px;
            padding: 0 10px;
            height: 38px;
            border: 1px solid #434343;
            border-radius: 19px;
            font-size: 16px;
            font-family: 'PingFang';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 38px;
            text-align: center;
            margin-right: 10px;
          }
        }

        .data {
          // width:  105px;
          height: 12px;
          font-size: 12px;
          font-family: 'PingFang';
          font-weight: 400;
          color: #FFFFFF;
          line-height: 38px;
        }
      }
    }
  }
}