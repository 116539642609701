.TopPhotoMob {
  width: 100%;
  height: 370px;

  .topContent {
    width: 100%;
    height: 100%;
    position: relative;

    .backPhoto {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .target {
      font-weight: 400;
      color: #FFFFFF;
      line-height: 70px;
      top: 92px;
      left: 15px;
      position: absolute;
    }

    .zh {
      font-size: 60px;
      font-family: "HanSansHWK";
    }

    .cn {
      font-size: 46px;
      font-family: 'HanSerifSC';
    }
  }
}